// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Rethink', Helvetica;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

code {
  font-family: 'Consolas', monospace;
  font-size: 12px;
  text-align: left;
  background-color: var(--shadow);
  color: var(--text);
  padding: 12px;
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,SAAS;EACT,UAAU;EACV,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;EACb,qBAAqB;AACvB","sourcesContent":["body {\n  font-family: 'Rethink', Helvetica;\n  margin: 0;\n  padding: 0;\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n}\n\ncode {\n  font-family: 'Consolas', monospace;\n  font-size: 12px;\n  text-align: left;\n  background-color: var(--shadow);\n  color: var(--text);\n  padding: 12px;\n  white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
