// src/components/Typewriter.tsx

import React, { useState, useEffect } from 'react';
import './Typewriter.css';

interface TypewriterProps {
  texts: string[];
  typingSpeed?: number;   // Speed of typing in ms per character
  deletingSpeed?: number; // Speed of deleting in ms per character
  delay?: number;         // Delay before typing the next text in ms
}

const Typewriter: React.FC<TypewriterProps> = ({
  texts,
  typingSpeed = 50,
  deletingSpeed = 30,
  delay = 2000,
}) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentText = texts[currentTextIndex];
    let timer: NodeJS.Timeout;

    if (isDeleting) {
      timer = setTimeout(() => {
        setDisplayedText(currentText.substring(0, displayedText.length - 1));
        if (displayedText.length - 1 === 0) {
          setIsDeleting(false);
          setCurrentTextIndex((prev) => (prev + 1) % texts.length);
        }
      }, deletingSpeed);
    } else {
      timer = setTimeout(() => {
        setDisplayedText(currentText.substring(0, displayedText.length + 1));
        if (displayedText.length + 1 === currentText.length) {
          setTimeout(() => setIsDeleting(true), delay);
        }
      }, typingSpeed);
    }

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, texts, currentTextIndex, typingSpeed, deletingSpeed, delay]);

  return (
    <h1 className="login-title">
      {displayedText}
      <span className="cursor"></span>
    </h1>
  );
};

export default Typewriter;
