// src/components/MessageList/components/SearchResultButtons.tsx
import React from 'react';
import { Message } from '../../types';
import SourceList from '../SourceList';

interface SearchResultButtonsProps {
  message: Message;
}

export function SearchResultButtons({ message }: SearchResultButtonsProps) {
  if (
    message.role !== 'assistant' || 
    !message.searchResults || 
    message.searchResults.length === 0 || 
    !message.searchResults[0].title
  ) {
    return null;
  }

  const sources = message.searchResults.map(result => ({
    title: result.title,
    url: result.url
  }));

  return <SourceList sources={sources} />;
}