// src/components/Chat/useChat.ts

import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Conversation, DocumentRef, Message, ThemeType,FileMetadata, SearchResult } from '../types';
import { fetchConversations, deleteConversation as deleteConversationUtil, fetchConversationMessages } from '../utils';
import { getCachedData, setCachedData, conversationsCache, messagesCache } from '../cacheUtils';
import { streamingApiCall, apiCall } from '../../apiWrapper';

const API_URL = process.env.REACT_APP_API_URL;

export const useChat = (theme: ThemeType, setTheme: (theme: ThemeType) => void, onOpenAnalytics: () => void) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);
  const [isNewConversation, setIsNewConversation] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(window.innerWidth > 768);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pastedDocuments, setPastedDocuments] = useState<Array<{ id: string, content: string }>>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const cachedConversations = getCachedData('conversations', conversationsCache);
    if (cachedConversations) {
      setConversations(cachedConversations);
    }
    fetchConversations(setConversations, setError);
  }, []);

  useEffect(() => {
    if (currentConversation) {
      const cachedMessages = getCachedData('messages', messagesCache) || {};
      setCachedData('messages', {
        ...cachedMessages,
        [currentConversation.id]: currentConversation.messages
      }, messagesCache);
    }
  }, [currentConversation?.messages]);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  useEffect(() => {
    if (currentConversation && currentConversation.messages) {
      // Mettre à jour le cache des messages
      const cachedMessages = getCachedData('messages', messagesCache) || {};
      const updatedCache = {
        ...cachedMessages,
        [currentConversation.id]: currentConversation.messages
      };
      setCachedData('messages', updatedCache, messagesCache);
  
      // Mettre à jour le cache des conversations
      const cachedConversations = getCachedData('conversations', conversationsCache) || [];
      const updatedConversations = cachedConversations.map(conv =>
        conv.id === currentConversation.id 
          ? { ...conv, messages: currentConversation.messages }
          : conv
      );
      setCachedData('conversations', updatedConversations, conversationsCache);
    }
  }, [currentConversation?.id, currentConversation?.messages]);


  const stopGeneration = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setIsLoading(false);
    }
  }, [abortController]);
  
  const fetchUpdatedConversation = useCallback(async (conversationId: string) => {
    try {
      const response = await apiCall<{ conversation: { id: string; title: string } }>(`${API_URL}/conversations/${conversationId}`);
      if (response && response.conversation && response.conversation.title) {
        setConversations(prevConversations =>
          prevConversations.map(conv =>
            conv.id === conversationId ? { ...conv, title: response.conversation.title } : conv
          )
        );
        setCurrentConversation(prevConv => 
          prevConv && prevConv.id === conversationId ? { ...prevConv, title: response.conversation.title } : prevConv
        );
      }
    } catch (error) {
      console.error('Error fetching updated conversation:', error);
    }
  }, []);
  const createNewMessage = (role: 'user' | 'assistant', text: string = ''): Message => {
    return {
      role,
      text,  // Même si vide, on garantit que c'est une chaîne
    };
  };
  
  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const [pendingConversations, setPendingConversations] = useState<Set<string>>(new Set());

const createNewConversation = useCallback(() => {
  // Si une génération est en cours, on ne crée pas de nouvelle conversation
  if (isGenerating) {
    return;
  }

  const tempId = `temp-${Date.now()}`;
  if (!pendingConversations.has(tempId)) {
    const newConversation: Conversation = {
      id: tempId,
      messages: [],
      title: 'New Conversation',
      updated_at: new Date().toISOString()
    };

    // Mettre à jour le cache des messages
    const cachedMessages = getCachedData('messages', messagesCache) || {};
    setCachedData('messages', {
      ...cachedMessages,
      [tempId]: []
    }, messagesCache);

    setPendingConversations(prev => new Set(prev).add(tempId));
    setCurrentConversation(newConversation);
    setIsNewConversation(true);
    setConversations(prev => [newConversation, ...prev]);
  }
}, [isGenerating, pendingConversations]);

const handleConversationSelect = useCallback(async (conversation: Conversation) => {
  if (isGenerating) return;
  
  setIsLoading(true);
  try {
    // Sauvegarder la conversation courante dans le cache avant de changer
    if (currentConversation && currentConversation.messages) {
      const cachedMessages = getCachedData('messages', messagesCache) || {};
      setCachedData('messages', {
        ...cachedMessages,
        [currentConversation.id]: currentConversation.messages
      }, messagesCache);
    }

    // Essayer d'abord de charger depuis le cache
    const cachedMessages = getCachedData('messages', messagesCache);
    const cachedConversationMessages = cachedMessages?.[conversation.id];

    if (cachedConversationMessages && cachedConversationMessages.length > 0) {
      // Si on a des messages en cache, les utiliser
      setCurrentConversation({
        ...conversation,
        messages: cachedConversationMessages
      });
    } else {
      // Si pas de cache, charger depuis l'API
      try {
        const messages = await fetchConversationMessages(conversation.id);
        if (messages && messages.length > 0) {
          // Mettre à jour le cache avec les nouveaux messages
          setCachedData('messages', {
            ...cachedMessages,
            [conversation.id]: messages
          }, messagesCache);

          setCurrentConversation({
            ...conversation,
            messages: messages
          });
        } else {
          // Si pas de messages, initialiser avec un tableau vide
          setCurrentConversation({
            ...conversation,
            messages: []
          });
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
        // En cas d'erreur, afficher un message d'erreur dans la conversation
        setCurrentConversation({
          ...conversation,
          messages: [{
            role: 'assistant',
            text: 'Failed to load messages. Please try refreshing the page.'
          }]
        });
      }
    }
    
    setIsNewConversation(false);
  } catch (error) {
    console.error('Error in handleConversationSelect:', error);
    setCurrentConversation({
      ...conversation,
      messages: [{
        role: 'assistant',
        text: 'An error occurred while loading the conversation.'
      }]
    });
  } finally {
    setIsLoading(false);
  }
}, [currentConversation, isGenerating]);

const handleSend = async (complete_input: string, input: string, tempFiles: FileMetadata[], documents: DocumentRef[]) => {
  if (!currentConversation) return;
  
  const newUserMessage: Message = {
    role: 'user',
    text: complete_input,
    files: tempFiles,
    document_refs: documents,
  };

  const initialBotMessage: Message = {
    role: 'assistant',
    text: '',
  };

  // Mise à jour initiale de la conversation et du cache
  setCurrentConversation(prev => {
    if (!prev) {
      const newConv = {
        id: `temp-${Date.now()}`,
        messages: [newUserMessage, initialBotMessage],
        title: 'New Conversation',
        updated_at: new Date().toISOString()
      };

      // Mettre à jour le cache
      const cachedMessages = getCachedData('messages', messagesCache) || {};
      setCachedData('messages', {
        ...cachedMessages,
        [newConv.id]: newConv.messages
      }, messagesCache);

      return newConv;
    }

    const updatedMessages = [...prev.messages, newUserMessage, initialBotMessage];
    
    // Mettre à jour le cache
    const cachedMessages = getCachedData('messages', messagesCache) || {};
    setCachedData('messages', {
      ...cachedMessages,
      [prev.id]: updatedMessages
    }, messagesCache);

    return {
      ...prev,
      messages: updatedMessages
    };
  });

  const controller = new AbortController();
  setAbortController(controller);
  setIsGenerating(true);
  
  let botMessage: Message = {
    role: 'assistant',
    text: '',
    files: [],
    searchResults: [],
  };

  try {
    if (input.trim() || tempFiles.length > 0 || documents.length > 0) {
      setIsLoading(true);

      const response = await streamingApiCall(`${API_URL}/chat`, {
        method: 'POST',
        body: JSON.stringify({
          conversation_id: isNewConversation ? null : currentConversation?.id,
          message: complete_input,
          files: tempFiles,
          document_refs: documents
        }),
        signal: controller.signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get reader from response body');
      }

      const decoder = new TextDecoder();
      let newConversationId: string | null = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(5).trim();

            if (data === '[DONE]') {
              setIsGenerating(false);
              setIsLoading(false);
              break;
            }

            try {
              const messageJson = JSON.parse(data);
              
              if (messageJson.content === 'Generation interrupted') {
                if (!botMessage.text) {
                  botMessage.text = 'Generation was interrupted';
                }
                setIsGenerating(false);
                break;
              }

              if (messageJson.content && messageJson.content !== '[DONE]') {
                botMessage.text += messageJson.content;
              }

              if (messageJson.image_url) {
                botMessage.files = botMessage.files || [];
                botMessage.files.push({
                  name: `generated_image_${botMessage.files.length + 1}.webp`,
                  type: 'image/webp',
                  url: messageJson.image_url[0],
                  justUploaded: true,
                });
              }

              if (!messageJson.scraped && messageJson.url) {
                botMessage.searchResults = botMessage.searchResults || [];
                botMessage.searchResults.push({
                  title: messageJson.websiteTitle,
                  url: messageJson.url
                });
              }

              if (messageJson.confirmation) {
                botMessage.confirmation = {
                  tool: messageJson.confirmation,
                  message: messageJson.content
                };
              }

              if (messageJson.conversation_id) {
                newConversationId = messageJson.conversation_id;
              }

              // Mise à jour de la conversation en cours avec mise à jour du cache
              setCurrentConversation(prevConversation => {
                if (!prevConversation) return null;
                
                const updatedMessages = [...prevConversation.messages];
                if (updatedMessages.length > 0 && 
                    updatedMessages[updatedMessages.length - 1] && 
                    updatedMessages[updatedMessages.length - 1].role === 'assistant') {
                  updatedMessages[updatedMessages.length - 1] = { ...botMessage };
                } else {
                  updatedMessages.push({ ...botMessage });
                }
                
                const updatedConversation = {
                  ...prevConversation,
                  id: newConversationId || prevConversation.id,
                  messages: updatedMessages,
                };

                // Mettre à jour le cache des messages
                const cachedMessages = getCachedData('messages', messagesCache) || {};
                setCachedData('messages', {
                  ...cachedMessages,
                  [updatedConversation.id]: updatedMessages
                }, messagesCache);

                return updatedConversation;
              });

            } catch (error) {
              console.error('Error processing message:', error);
            }
          }
        }
      }

      // Mise à jour finale des conversations avec le cache
      setConversations(prevConversations => {
        const updatedConversation = {
          id: newConversationId || currentConversation?.id || `temp-${Date.now()}`,
          messages: [...(currentConversation?.messages || []), newUserMessage, botMessage],
          title: currentConversation?.title || 'New Conversation',
          updated_at: new Date().toISOString()
        };

        // Mettre à jour le cache des conversations
        const updatedConversations = prevConversations.map(conv =>
          conv.id === updatedConversation.id || conv.id === 'temp-id'
            ? updatedConversation
            : conv
        );
        setCachedData('conversations', updatedConversations, conversationsCache);

        return updatedConversations;
      });

      setIsNewConversation(false);
    }
  } catch (err) {
    if (err instanceof Error && err.name === 'AbortError') {
      const interruptionMessage: Message = { 
        role: 'assistant' as const, // Spécifier explicitement le type
        text: botMessage?.text || 'Generation was interrupted' 
      };

      setCurrentConversation(prev => {
        if (!prev) return null;
        const updatedMessages = [...prev.messages];
        
        if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].role === 'assistant') {
          updatedMessages[updatedMessages.length - 1] = interruptionMessage;
        } else {
          updatedMessages.push(interruptionMessage);
        }

        // Mettre à jour le cache
        const cachedMessages = getCachedData('messages', messagesCache) || {};
        setCachedData('messages', {
          ...cachedMessages,
          [prev.id]: updatedMessages
        }, messagesCache);

        return {
          ...prev,
          messages: updatedMessages
        };
      });
    } else {
      console.error('Error:', err);
      const errorMessage: Message = {
        role: 'assistant' as const, // Spécifier explicitement le type
        text: "An error occurred during message processing."
      };

      setCurrentConversation(prev => {
        if (!prev) return null;
        const updatedMessages = [...prev.messages, errorMessage];
        
        // Mettre à jour le cache
        const cachedMessages = getCachedData('messages', messagesCache) || {};
        setCachedData('messages', {
          ...cachedMessages,
          [prev.id]: updatedMessages
        }, messagesCache);

        return {
          ...prev,
          messages: updatedMessages
        };
      });
    }
  } finally {
    setAbortController(null);
    setIsGenerating(false);
    setIsLoading(false);
    setPastedDocuments([]);
  }
};

  const handleConfirmation = useCallback(async (tool: string) => {
    if (currentConversation && currentConversation.messages.length > 0) {
      const lastMessage = currentConversation.messages[currentConversation.messages.length - 1];
      if (lastMessage.confirmation) {
        // Supprime le message de confirmation
        const updatedMessages = currentConversation.messages.slice(0, -1);
        setCurrentConversation(prev => prev ? {
          ...prev,
          messages: updatedMessages
        } : null);
  
        // Appelle l'outil approprié selon la confirmation
        if (tool === 'generate_image' || tool === 'search_online') {
          await handleSend(`${tool} as confirmed`, '', [], []);
        }
      }
    }
  }, [currentConversation, handleSend]);

  const deleteConversation = useCallback((id: string) => {
    // Supprimer la conversation de l'état local
    setConversations(prev => prev.filter(conv => conv.id !== id));
    
    // Si la conversation supprimée est la conversation courante, la réinitialiser
    if (currentConversation?.id === id) {
      setCurrentConversation(null);
      setIsNewConversation(false);
    }
  
    // Supprimer du cache
    const cachedConversations = getCachedData('conversations', conversationsCache);
    if (cachedConversations) {
      const updatedCache = cachedConversations.filter(conv => conv.id !== id);
      setCachedData('conversations', updatedCache, conversationsCache);
    }
  
    // Supprimer les messages du cache
    const cachedMessages = getCachedData('messages', messagesCache);
    if (cachedMessages) {
      const { [id]: _, ...restMessages } = cachedMessages;
      setCachedData('messages', restMessages, messagesCache);
    }
  
    // Appeler l'API pour supprimer la conversation
    apiCall(`${API_URL}/conversations/${id}`, {
      method: 'DELETE'
    }).catch(error => {
      console.error('Error deleting conversation:', error);
      
      // Au lieu de setError, créer un nouveau message de l'assistant
      const errorMessage: Message = {
        role: 'assistant',
        text: 'Failed to delete conversation. Please try again later.',
      };
  
      // Ajouter le message à la conversation courante si elle existe
      if (currentConversation) {
        setCurrentConversation(prev => prev ? {
          ...prev,
          messages: [...prev.messages, errorMessage]
        } : null);
      } else {
        // Si pas de conversation courante, en créer une nouvelle avec le message d'erreur
        const newConversation: Conversation = {
          id: `temp-${Date.now()}`,
          messages: [errorMessage],
          title: 'System Message',
          updated_at: new Date().toISOString()
        };
        setCurrentConversation(newConversation);
        setConversations(prev => [newConversation, ...prev]);
      }
    });
  }, [currentConversation]);

  const handlePastedDocument = (document: { id: string, content: string }) => {
    setPastedDocuments(prev => [...prev, document]);
  };

  const removePastedDocument = (id: string) => {
    setPastedDocuments(prev => prev.filter(doc => doc.id !== id));
  };

  return {
    // Ajouter isGenerating dans le return
    isGenerating,
    conversations,
    currentConversation,
    isNewConversation,
    isSidebarOpen,
    isLoading,
    error,
    theme,
    chatContainerRef,
    handleConversationSelect,
    createNewConversation,
    deleteConversation,
    handleSend,
    toggleSidebar,
    setIsSidebarOpen,
    setTheme,
    pastedDocuments,
    handlePastedDocument,
    removePastedDocument,
    onOpenAnalytics,
    fetchUpdatedConversation,
    handleConfirmation,
    stopGeneration,
  };
};