import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DocumentRef, FileMetadata, Project } from '../types';
import './WelcomeInput.css';
import { getFileIcon } from '../utils';
import { getProjectEmoji } from './emojiUtils';

interface WelcomeInputProps {
  onSend: (
    complete_input: string, 
    input: string, 
    tempFiles: FileMetadata[], 
    documents: DocumentRef[]
  ) => Promise<void>;
  isAssistantResponding: boolean;
  pastedDocumentRefs: DocumentRef[];
  handlePastedDocument: (document: DocumentRef) => void;
  removePastedDocument: (id: string) => void;
  isSidebarOpen: boolean;
  projects: Project[]; // Ajout de la prop projects
  onSelectProject: (project: Project) => void; 

}

const WelcomeInput = React.forwardRef<
  { handleFileUpload: (file: File) => void },
  WelcomeInputProps
>(({
  onSend,
  isAssistantResponding,
  pastedDocumentRefs,
  handlePastedDocument,
  removePastedDocument,
  isSidebarOpen,
  projects,
  onSelectProject
}, ref) => {
  const [input, setInput] = useState<string>('');
  const [tempFiles, setTempFiles] = useState<FileMetadata[]>([]);
  const [fileWarning, setFileWarning] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // Ajout des nouveaux états pour les suggestions
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [projectSuggestions, setProjectSuggestions] = useState<Project[]>([]);
  const [cursorPosition, setCursorPosition] = useState<number | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dragCounter = useRef<number>(0);
  const MAX_FILES = 5;
  const MAX_PREVIEW_LENGTH = 1000;
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    // Reset to auto first to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set to actual content height, with a minimum of 20px and maximum of 180px
    const newHeight = Math.max(20, Math.min(textarea.scrollHeight, 180));
    textarea.style.height = `${newHeight}px`;
  };

  const handleSuggestionClick = (suggestionText: string) => {
    setInput(suggestionText);
    if (textareaRef.current) {
      textareaRef.current.focus();
      adjustTextareaHeight(textareaRef.current);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setInput(newValue);

    // Adjust textarea height
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  
    // Gestion des suggestions de projets
    if (newValue.includes('#')) {
      const lastHashIndex = newValue.lastIndexOf('#');
      const textAfterHash = newValue.slice(lastHashIndex + 1).toLowerCase();
      
      if (textAfterHash === '') {
        // Si rien n'est tapé après le #, montrer tous les projets
        setProjectSuggestions(projects);
        setShowSuggestions(true);
        const position = calculateHashPosition();
        setCursorPosition(position);
      } else {
        // Filtrer les projets qui correspondent au texte tapé après le #
        const filteredProjects = projects.filter(project => 
          project.name.toLowerCase().includes(textAfterHash)
        );
        setProjectSuggestions(filteredProjects);
        setShowSuggestions(filteredProjects.length > 0);
        const position = calculateHashPosition();
        setCursorPosition(position);
      }
    } else {
      setShowSuggestions(false);
      setProjectSuggestions([]);
      setCursorPosition(null);
    }
  };

  const handleProjectSelect = (project: Project) => {
    const inputParts = input.split('#');
    const newInput = inputParts[0] + '#' + project.name + ' ' + 
      (inputParts[1]?.split(' ').slice(1).join(' ') || '');
    setInput(newInput);
    setShowSuggestions(false);
    onSelectProject(project); // Au lieu de onProjectSelect
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };
  // Gestion des touches spéciales
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (showSuggestions && event.key === 'Escape') {
      setShowSuggestions(false);
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (showSuggestions && projectSuggestions.length > 0) {
        handleProjectSelect(projectSuggestions[0]);
      } else {
        handleSend();
      }
    }
  };

  const calculateHashPosition = () => {
    if (!textareaRef.current) return null;
    const textBeforeCursor = input.substring(0, textareaRef.current.selectionStart);
    const hashIndex = textBeforeCursor.lastIndexOf('#');
    if (hashIndex === -1) return null;
    
    // Calculer la position du caractère "#" dans le textarea
    const textArea = textareaRef.current;
    const text = textArea.value;
    const textBeforeHash = text.substring(0, hashIndex);
    
    // Créer un élément temporaire pour mesurer la position
    const temp = document.createElement('textarea');
    temp.style.position = 'absolute';
    temp.style.visibility = 'hidden';
    temp.style.height = 'auto';
    temp.style.width = textArea.offsetWidth + 'px';
    temp.style.font = window.getComputedStyle(textArea).font;
    temp.value = textBeforeHash;
    document.body.appendChild(temp);
    
    const { offsetWidth } = temp;
    document.body.removeChild(temp);
    
    return offsetWidth;
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [input]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSend = async () => {
    if (input.trim() === '' || isLoading) return;
            
    setIsLoading(true);
    try {
      const currentInput = input;
      const completeInput = [       
        ...pastedDocumentRefs.map(doc => doc.type === 'image' ? `[Image: ${doc.id}]` : doc.content),
        currentInput.trim()
      ].filter(Boolean).join('\n\n');
      
      setInput('');
      setTempFiles([]);
      
      // Reset textarea height properly
      if (textareaRef.current) {
        textareaRef.current.style.height = '20px'; // Set to minimum height
        adjustTextareaHeight(textareaRef.current);
      }
      
      await onSend(completeInput, currentInput.trim(), tempFiles, pastedDocumentRefs);
      
      pastedDocumentRefs.forEach(doc => removePastedDocument(doc.id));
      
    } catch (error) {
      console.error('Error sending message:', error);
      setInput(input);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = (file: File) => {
    if (tempFiles.length >= MAX_FILES) {
      setFileWarning(`You can only add up to ${MAX_FILES} files.`);
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target?.result as string;
      let newDocument: DocumentRef | null = null;
  
      // Gérer les différents types de fichiers comme dans InputArea
      if (file.type.startsWith('image/')) {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'image',
          mime_type: file.type,
        };
      } else if (file.type === 'text/plain') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'text',
          mime_type: file.type,
        };
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'xlsx',
          mime_type: file.type,
        };
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'pptx',
          mime_type: file.type,
        };
      } else if (file.type === 'application/pdf') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'pdf',
          mime_type: file.type,
        };
      } else {
        const newFile: FileMetadata = {
          name: file.name,
          type: file.type,
          url: url
        };
        setTempFiles(prev => [...prev, newFile]);
        return;
      }
      if (newDocument) {
        handlePastedDocument(newDocument);
      } else {
        const newFile: FileMetadata = {
          name: file.name,
          type: file.type,
          url: url
        };
        setTempFiles(prev => [...prev, newFile]);
      }
    };
  
    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      setFileWarning('Error reading file. Please try again.');
    };
  
    reader.readAsDataURL(file); 
  };

  const handleRemoveFile = (index: number) => {
    setTempFiles(prev => prev.filter((_, i) => i !== index));
    setFileWarning('');
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(event.target as Node) &&
        !textareaRef.current?.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current += 1;
      if (e.dataTransfer?.types.includes('Files')) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {  // Changé ici
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      dragCounter.current = 0;
      
      const files = Array.from(e.dataTransfer?.files || []);
      files.forEach(file => {
        handleFileUpload(file);
      });
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData('text');
    const pastedItems = event.clipboardData.items;

    if (pastedText.length > MAX_PREVIEW_LENGTH) {
      event.preventDefault();
      const newDocument: DocumentRef = {
        id: uuidv4(),
        content: pastedText,
        name: 'Pasted text',
        type: 'text'
      };
      handlePastedDocument(newDocument);
      return;
    }

    for (let i = 0; i < pastedItems.length; i++) {
      const item = pastedItems[i];
      if (item.kind === 'file') {
        const blob = item.getAsFile();
        if (blob) {
          event.preventDefault();
          handleFileUpload(blob);
          break;
        }
      }
    }
  };

  const triggerFileUpload = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const container = document.querySelector('.welcome-input-container');
    if (!container) return;
  
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        const viewportHeight = window.innerHeight;
        
        if (height > viewportHeight) {
          container.classList.add('scrollable');
        } else {
          container.classList.remove('scrollable');
        }
      }
    });
  
    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, []);


  useEffect(() => {
    if (!isAssistantResponding && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isAssistantResponding]);

  useEffect(() => {
    if (fileWarning) {
      const timer = setTimeout(() => {
        setFileWarning('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [fileWarning]);

  return (
    <div 
      className={`welcome-input-container ${isDragging ? 'dragging' : ''}`}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current += 1;
        if (e.dataTransfer?.types.includes('Files')) {
          setIsDragging(true);
        }
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current -= 1;
        if (dragCounter.current === 0) {
          setIsDragging(false);
        }
      }}
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        
        const files = Array.from(e.dataTransfer?.files || []);
        files.forEach(file => {
          handleFileUpload(file);
        });
      }}
    >
      <div className="welcome-message">
        <h1>Get started with Racine.ai</h1>
      </div>
      
      {fileWarning && <div className="file-warning">{fileWarning}</div>}
      
      {tempFiles.length > 0 && (
        <div className="file-preview-container">
          {tempFiles.map((file, index) => (
            <div key={index} className="temp-file-container">
              <i className={`fas ${getFileIcon(file.type)}`}></i>
              <span>{file.name}</span>
              <button className="remove-file-btn" onClick={() => handleRemoveFile(index)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          ))}
        </div>
      )}
  
      {pastedDocumentRefs.length > 0 && (
        <div className="pasted-documents-preview-welcome">
          {pastedDocumentRefs.map((doc) => (
            <div key={doc.id} className={`pasted-document ${doc.type === 'image' ? 'image-document' : 'text-document'}`}>
              {doc.type === 'image' ? (
                <img 
                  src={`data:${doc.mime_type};base64,${doc.content}`}
                  alt={doc.name} 
                  className="pasted-image-preview"
                />
              ) : (
                <div className="text-preview">
                  <p>{doc.content.slice(0, 200)}{doc.content.length > 200 ? '...' : ''}</p>
                </div>
              )}
              <div className="document-info">
                <span className="document-name">{doc.name}</span>
                <button className="remove-document-btn" onClick={() => removePastedDocument(doc.id)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
  
      <div className="welcome-input-wrapper">
        <div className="welcome-input-content">
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
            accept="*/*"
            style={{ display: 'none' }}
            className="file-input"
          />
          <button 
            className="upload-btn" 
            onClick={triggerFileUpload}
          >
            <i className="fas fa-paperclip"></i>
          </button>
          <div className="textarea-container">
            {showSuggestions && projectSuggestions.length > 0 && cursorPosition !== null && (
              <div 
                className="project-suggestion-popup"
                style={{
                  left: `${cursorPosition + 10}px`
                }}
              >
                <span className="project-emoji">{getProjectEmoji(projectSuggestions[0].name)}</span>
                <span>{projectSuggestions[0].name}</span>
              </div>
            )}
            <textarea 
              className="welcome-search-area"
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              placeholder="Type your first message here..."
              rows={1}
            />
              {showSuggestions && projectSuggestions.length > 0 && (
              <div 
                ref={suggestionsRef}
                className="project-suggestions"
              >
                {projectSuggestions.map((project) => (
                  <div
                    key={project.id}
                    className="project-suggestion-item"
                    onClick={() => handleProjectSelect(project)}
                  >
                    <span className="project-emoji">{getProjectEmoji(project.name)}</span>
                    <span className="project-name">{project.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <button 
            className="welcome-send-btn" 
            onClick={handleSend}
          >
            <svg width="30" height="30" viewBox="-5 -5 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="send-icon">
              <path d="M10.6012 15.3935L24.6111 1.38887M10.9995 16.0233L14.1762 22.3769C14.9439 23.9122 15.3277 24.68 15.8113 24.8858C16.231 25.0645 16.7107 25.032 17.1026 24.7987C17.5542 24.5299 17.8315 23.7175 18.3863 22.093L24.3885 4.51488C24.8719 3.09941 25.1135 2.39166 24.9481 1.92347C24.8042 1.51618 24.4838 1.19579 24.0766 1.05189C23.6083 0.886479 22.9006 1.12815 21.4851 1.61148L3.90692 7.61379C2.28241 8.1685 1.47014 8.44586 1.20126 8.89743C0.967906 9.2893 0.935572 9.76901 1.11422 10.1886C1.32008 10.6722 2.08778 11.0561 3.62317 11.8237L9.97669 15.0006C10.2297 15.1271 10.3562 15.1902 10.4657 15.2747C10.5631 15.3498 10.6503 15.437 10.7252 15.5342C10.8098 15.6438 10.873 15.7703 10.9995 16.0233Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>

        <div className="query-suggestions">
          <div 
            className="suggestion-item"
            onClick={() => handleSuggestionClick("Generate a creative story about space exploration")}
            style={{ cursor: 'pointer' }}
          >
            <div className="suggestion-icon">
              <i className="fas fa-magic" style={{color: '#FFB6C1'}}></i>
            </div>
            <span>Generate a creative story about space exploration</span>
          </div>
          
          <div 
            className="suggestion-item"
            onClick={() => handleSuggestionClick("Help me debug this React component")}
            style={{ cursor: 'pointer' }}
          >
            <div className="suggestion-icon">
              <i className="fas fa-code" style={{color: '#98FB98'}}></i>
            </div>
            <span>Help me debug this React component</span>
          </div>
          
          <div 
            className="suggestion-item"
            onClick={() => handleSuggestionClick("Explain how quantum computing works")}
            style={{ cursor: 'pointer' }}
          >
            <div className="suggestion-icon">
              <i className="fas fa-question-circle" style={{color: '#87CEEB'}}></i>
            </div>
            <span>Explain how quantum computing works</span>
          </div>
          
          <div 
            className="suggestion-item"
            onClick={() => handleSuggestionClick("Give me creative ideas for a project")}
            style={{ cursor: 'pointer' }}
          >
            <div className="suggestion-icon">
              <i className="fas fa-lightbulb" style={{color: '#DDA0DD'}}></i>
            </div>
            <span>Give me creative ideas for a project</span>
          </div>
        </div>
  
        <div className="project-widgets">
          {projects.map((project) => (
            <div 
              key={project.id} 
              className="project-widget"
              onClick={() => onSelectProject(project)}
              data-tooltip={project.name}
            >
              <span className="project-emoji">
                {getProjectEmoji(project.name)}
              </span>
            </div>
          ))}
        </div>
          
        {isDragging && (
          <div className={`drag-drop-popup ${isDragging ? 'active' : 'leaving'}`}>
            <i className="fas fa-cloud-upload-alt"></i>
            <p>Drop your files here</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default WelcomeInput;