// src/components/MessageList/index.tsx
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Message } from '../types';
import { useScrollBehavior } from './hooks/useScrollBehavior';
import { useCodeBlockStates } from './hooks/useCodeBlockStates';
import { useCopyToClipboard } from './hooks/useCopyToClipboard';
import { MessageContent } from './components/MessageContent';
import { withRenderLogging, useRenderLogging } from '../../DebugRenders';
import './MessageList.css';
import './Markdown.css';

const CHUNK_SIZE = 10;
const BUFFER_SIZE = 20;
const DEBUG = process.env.NODE_ENV === 'development';

interface MessageListProps {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  fetchUpdatedConversation: (conversationId: string) => Promise<void>;
  currentConversationId: string | null;
  theme: 'dark' | 'light';
  onConfirm: (tool: string) => void;
}

const MessageListComponent: React.FC<MessageListProps> = ({
  messages,
  isLoading,
  error,
  chatContainerRef,
  fetchUpdatedConversation,
  currentConversationId,
  theme,
  onConfirm
}) => {
  useRenderLogging('MessageList');

  const lastMessageRef = useRef<HTMLDivElement>(null);
  const prevMessagesLengthRef = useRef(messages.length);
  const prevConversationIdRef = useRef<string | null>(null);
  const lastMessageLengthRef = useRef(0);
  const lastLoadingStateRef = useRef(isLoading);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreTriggerRef = useRef<HTMLDivElement>(null);
  const bottomTriggerRef = useRef<HTMLDivElement>(null);
  const scrollDirectionRef = useRef<'up' | 'down'>('down');

  const [visibleRange, setVisibleRange] = useState({
    start: Math.max(0, messages.length - CHUNK_SIZE),
    end: messages.length
  });

  const { 
    shouldAutoScroll, 
    setShouldAutoScroll, 
    scrollToBottom, 
    isNearBottom 
  } = useScrollBehavior(chatContainerRef, lastMessageRef);
  
  const { codeBlockStates } = useCodeBlockStates(messages);
  const { copiedStates, copyToClipboard } = useCopyToClipboard();

  const memoizedMessages = useMemo(() => {
    if (DEBUG) {
      console.time('Message Processing');
    }

    const processedMessages = messages.reduce((acc: Message[], curr, index) => {
      if (index === 0) {
        return [{ ...curr }];
      }

      const lastMessage = acc[acc.length - 1];
      
      if (lastMessage.role !== curr.role) {
        acc.push({ ...curr });
        return acc;
      }

      if (curr.role === 'user') {
        return acc;
      }

      if (curr.role === 'assistant') {
        const updatedMessage = {
          ...lastMessage,
          text: (curr.text?.length ?? 0) > (lastMessage.text?.length ?? 0)
            ? curr.text 
            : lastMessage.text,
          files: [...(lastMessage.files ?? []), ...(curr.files ?? [])],
          searchResults: curr.searchResults 
            ? [...(lastMessage.searchResults ?? []), ...curr.searchResults]
            : lastMessage.searchResults,
          confirmation: curr.confirmation ?? lastMessage.confirmation
        };
        
        acc[acc.length - 1] = updatedMessage;
      }

      return acc;
    }, []);

    if (DEBUG) {
      console.timeEnd('Message Processing');
    }

    return processedMessages.slice(visibleRange.start, visibleRange.end);
  }, [messages, visibleRange]);

  useEffect(() => {
    if (!loadMoreTriggerRef.current || !chatContainerRef.current) return;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const trigger = entries[0];
      if (trigger.isIntersecting) {
        setVisibleRange(prev => {
          const newStart = Math.max(0, prev.start - CHUNK_SIZE);
          const newEnd = prev.end;
          
          if (scrollDirectionRef.current === 'up' && 
              (newEnd - newStart) > (CHUNK_SIZE * 2)) {
            return {
              start: newStart,
              end: newEnd - CHUNK_SIZE + BUFFER_SIZE
            };
          }
          
          return {
            start: newStart,
            end: newEnd
          };
        });
      }
    };

    const handleScroll = () => {
      if (!chatContainerRef.current) return;
      
      if (DEBUG) {
        console.time('Scroll Handling');
      }

      const { scrollTop } = chatContainerRef.current;
      
      scrollDirectionRef.current = 
        scrollTop < (chatContainerRef.current as any).lastScrollTop ? 'up' : 'down';
      
      (chatContainerRef.current as any).lastScrollTop = scrollTop;

      if (DEBUG) {
        console.timeEnd('Scroll Handling');
      }
    };

    chatContainerRef.current.addEventListener('scroll', handleScroll);
    
    observerRef.current = new IntersectionObserver(handleIntersection, {
      root: chatContainerRef.current,
      threshold: 0.1
    });

    observerRef.current.observe(loadMoreTriggerRef.current);

    return () => {
      observerRef.current?.disconnect();
      chatContainerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!bottomTriggerRef.current || !chatContainerRef.current) return;

    const handleBottomIntersection = (entries: IntersectionObserverEntry[]) => {
      const trigger = entries[0];
      if (trigger.isIntersecting) {
        setVisibleRange(prev => {
          const newStart = prev.start;
          const newEnd = Math.min(messages.length, prev.end + CHUNK_SIZE);
          
          if (scrollDirectionRef.current === 'down' && 
              (newEnd - newStart) > (CHUNK_SIZE * 2)) {
            return {
              start: newStart + CHUNK_SIZE - BUFFER_SIZE,
              end: newEnd
            };
          }
          
          return {
            start: newStart,
            end: newEnd
          };
        });
      }
    };

    const bottomObserver = new IntersectionObserver(handleBottomIntersection, {
      root: chatContainerRef.current,
      threshold: 0.1
    });

    bottomObserver.observe(bottomTriggerRef.current);

    return () => bottomObserver.disconnect();
  }, [messages.length]);

  useEffect(() => {
    if (currentConversationId !== prevConversationIdRef.current) {
      prevConversationIdRef.current = currentConversationId;
      setShouldAutoScroll(true);
      setVisibleRange({
        start: Math.max(0, messages.length - CHUNK_SIZE),
        end: messages.length
      });
    }
  }, [currentConversationId, messages.length, setShouldAutoScroll]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    const currentLastMessageLength = lastMessage?.text?.length || 0;
    const messageChanged = currentLastMessageLength !== lastMessageLengthRef.current;
    
    if (lastLoadingStateRef.current && !isLoading) {
      lastLoadingStateRef.current = isLoading;
      lastMessageLengthRef.current = currentLastMessageLength;
      return;
    }
    
    lastLoadingStateRef.current = isLoading;

    if (shouldAutoScroll && (
      (isLoading && messageChanged) ||
      (messages.length > prevMessagesLengthRef.current && isNearBottom())
    )) {
      scrollToBottom(true);
      setVisibleRange({
        start: Math.max(0, messages.length - CHUNK_SIZE),
        end: messages.length
      });
    }

    lastMessageLengthRef.current = currentLastMessageLength;
    prevMessagesLengthRef.current = messages.length;
  }, [messages, isLoading, shouldAutoScroll, scrollToBottom, isNearBottom]);

  const renderMessage = useCallback((message: Message, index: number, isLast: boolean) => {
    if (DEBUG) {
      console.time(`Render Message ${index}`);
    }

    const renderedMessage = (
      <div 
        key={`msg-${index}-${message.text?.substring(0, 20) || 'no-text'}`}
        className={`chat-message ${message.role} ${
          (message.document_refs?.length ?? 0) > 0 && (!message.text || message.text.trim() === "") 
            ? 'document-only' 
            : ''
        }`}
        ref={isLast ? lastMessageRef : null}
      >
        <MessageContent 
          message={message}
          index={index}
          codeBlockStates={codeBlockStates}
          copiedStates={copiedStates}
          copyToClipboard={copyToClipboard}
          theme={theme}
          onConfirm={onConfirm}
        />
      </div>
    );

    if (DEBUG) {
      console.timeEnd(`Render Message ${index}`);
    }

    return renderedMessage;
  }, [codeBlockStates, copiedStates, copyToClipboard, theme, onConfirm]);

  return (
    <div className="messages" ref={chatContainerRef}>
      {visibleRange.start > 0 && (
        <div ref={loadMoreTriggerRef} className="load-more-trigger" />
      )}
      {memoizedMessages.map((message, index) => 
        renderMessage(
          message,
          index + visibleRange.start,
          index === memoizedMessages.length - 1
        )
      )}
      {visibleRange.end < messages.length && (
        <div ref={bottomTriggerRef} className="load-more-trigger bottom" />
      )}
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner" />
        </div>
      )}
      {error && (
        <div className="chat-message assistant error">
          {error}
        </div>
      )}
    </div>
  );
};

// Fix the export to properly use the HOC
const MemoizedMessageList = React.memo(MessageListComponent);
const LoggedMessageList = withRenderLogging(MemoizedMessageList, 'MessageList');

// Export the logged component
export default LoggedMessageList;