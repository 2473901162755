// src/components/MessageList/components/MessageContent.tsx
import React, { memo } from 'react';
import { Message } from '../../types';
import { DocumentPreviews } from './DocumentPreviews';
import { MessageFiles } from './MessageFiles';
import { MessageText } from './MessageText';
import { SearchResultButtons } from './SearchResultButtons';
import ConfirmationUI from './ConfirmationUI';

interface MessageContentProps {
  message: Message;
  index: number;
  codeBlockStates: { [key: string]: boolean };
  theme: 'dark' | 'light';
  onConfirm?: (tool: string) => void;
}

export const MessageContent = memo(function MessageContent({ 
  message, 
  index,
  codeBlockStates,
  theme,
  onConfirm
}: MessageContentProps) {
  const hasOnlyDocumentRefs = message.document_refs?.length ? 
    (message.document_refs?.length > 0 && (!message.text || message.text.trim() === "")) : 
    false;

  if (hasOnlyDocumentRefs) {
    return <DocumentPreviews documentRefs={message.document_refs} msgIndex={index} />;
  }

  // New function to remove document content from message text
  const getFilteredMessageText = () => {
    if (!message.text || !message.document_refs?.length) {
      return message.text;
    }

    let filteredText = message.text;
    message.document_refs.forEach(doc => {
      if (doc.content && filteredText.includes(doc.content)) {
        filteredText = filteredText.replace(doc.content, '').trim();
      }
    });
    
    return filteredText;
  };

  if (hasOnlyDocumentRefs) {
    return <DocumentPreviews documentRefs={message.document_refs} msgIndex={index} />;
  }

  
  return (
    <div className="message-content">
      <div className="message-text-area">
        {getFilteredMessageText() !== "\n\n" && (
          <MessageText 
            text={getFilteredMessageText()}
            role={message.role}
            msgIndex={index}
            documentRefs={message.document_refs}
            codeBlockStates={codeBlockStates}
            theme={theme}
          />
        )}
        {message.confirmation && (
          <ConfirmationUI 
            confirmation={message.confirmation}
            onConfirm={onConfirm}
          />
        )}
        <MessageFiles files={message.files} msgIndex={index} />
        <SearchResultButtons message={message} />
      </div>
      <DocumentPreviews documentRefs={message.document_refs} msgIndex={index} />
    </div>
  );
}, (prevProps, nextProps) => {
  // Only re-render if:
  // 1. Role changes
  // 2. Theme changes
  // 3. Index changes
  // 4. Text changes significantly (more than 10 characters)
  // 5. Confirmation status changes
  // 6. Files change
  const prevLength = prevProps.message.text?.length || 0;
  const nextLength = nextProps.message.text?.length || 0;
  const lengthDiff = Math.abs(prevLength - nextLength);
  
  const hasSignificantTextChange = lengthDiff >= 10;
  const hasConfirmationChange = 
    Boolean(prevProps.message.confirmation) !== Boolean(nextProps.message.confirmation);
  const hasFilesChange = 
    (prevProps.message.files?.length || 0) !== (nextProps.message.files?.length || 0);

  // Return true if the components are equal (no re-render needed)
  return (
    prevProps.message.role === nextProps.message.role &&
    prevProps.theme === nextProps.theme &&
    prevProps.index === nextProps.index &&
    !hasSignificantTextChange &&
    !hasConfirmationChange &&
    !hasFilesChange
  );
});

// Add display name for debugging purposes
MessageContent.displayName = 'MessageContent';

export default MessageContent;