// src/components/Chat/Chat.tsx

import React, { useEffect, useState,useRef } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ChatHeader from './ChatHeader';
import ChatContent from './ChatContent';
import { useChat } from './useChat';
import { getCachedImage } from '../../imageCache';
import { UserInfo, ThemeType } from '../types';
import '../../App.css';
import '../../themes.css';
import './Chat.css';

interface ChatProps {
  userInfo: UserInfo | null;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  theme: ThemeType;
  setTheme: (newTheme: ThemeType) => void;
  onLogout: () => Promise<void>;
}

function Chat({ userInfo, onOpenSettings, onOpenAnalytics, theme, setTheme, onLogout }: ChatProps) {
  const {
    conversations,
    currentConversation,
    isNewConversation,
    isSidebarOpen,
    isLoading,
    error,
    chatContainerRef,
    handleConversationSelect,
    createNewConversation,
    deleteConversation,
    handleSend,
    toggleSidebar,
    setIsSidebarOpen,
    fetchUpdatedConversation,
    handleConfirmation,
    stopGeneration,
    isGenerating,
  } = useChat(theme, setTheme, onOpenAnalytics);

  const [profileImageUrl, setProfileImageUrl] = useState<string>('/default-avatar.png');

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Vérifier si on quitte vraiment la zone (pas un élément enfant)
    if (e.currentTarget.contains(e.relatedTarget as Node)) {
      return;
    }
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const chatContentRef = useRef<{ handleFilesDropped: (files: File[]) => void }>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0 && chatContentRef.current) {
      chatContentRef.current.handleFilesDropped(files);
    }
  };

  useEffect(() => {
    if (userInfo?.picture) {
      getCachedImage(userInfo.picture)
        .then(setProfileImageUrl)
        .catch(() => setProfileImageUrl('profile-icon-design-free-vector.jpg'));
    }
  }, [userInfo?.picture]);

  return (
    <div 
      className={`App ${isSidebarOpen ? 'sidebar-open' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {isDragging && ( // Utilisez une condition ici au lieu d'une classe
        <div className="drag-drop-overlay">
          <div className="drag-drop-content">
            <div className="drag-drop-icon">
              <svg 
                viewBox="0 0 24.00 24.00" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="drag-drop-text">
              Drop your files here
            </div>
          </div>
        </div>
      )}

    <Sidebar
      conversations={conversations}
      currentConversation={currentConversation}
      setCurrentConversation={handleConversationSelect}
      createNewConversation={createNewConversation}
      deleteConversation={deleteConversation}
      isSidebarOpen={isSidebarOpen}
      setIsSidebarOpen={setIsSidebarOpen}
      userInfo={userInfo}
      onOpenSettings={onOpenSettings}
      onOpenAnalytics={onOpenAnalytics}
      onLogout={onLogout}
    />
    <div className="App-header">
      <ChatHeader toggleSidebar={toggleSidebar} />
      <ChatContent
        ref={chatContentRef}
        currentConversation={currentConversation || {
          id: '',
          messages: [],
          title: '',
          updated_at: new Date().toISOString()
        }}
        isLoading={isLoading}
        error={error}
        theme={theme}
        chatContainerRef={chatContainerRef}
        handleSend={handleSend}
        setTheme={setTheme}
        isSidebarOpen={isSidebarOpen}
        fetchUpdatedConversation={fetchUpdatedConversation}
        handleConfirmation={handleConfirmation}
        stopGeneration={stopGeneration}
        isGenerating={isGenerating}
      />
    </div>
  </div>
);
}

export default Chat;