// src/components/TableFormatting.tsx
import React, { useRef, useEffect } from 'react';
import './TableStyles.css';

interface CopyTableProps {
  children: React.ReactNode;
}

export const CopyTableWrapper: React.FC<CopyTableProps> = ({ children }) => {
  const tableRef = useRef<HTMLTableElement | null>(null);
  
  useEffect(() => {
    const tableElement = tableRef.current;
    if (!tableElement) return;
    
    // Add tabindex to make the table focusable but don't change visual style
    tableElement.setAttribute('tabindex', '0');
    
    // Add title to indicate the table is copyable
    tableElement.setAttribute('title', 'Cliquez pour sélectionner le tableau et utilisez Ctrl+C pour copier');
    
    const handleTableClick = (e: MouseEvent) => {
      // Select the entire table when clicked
      const selection = window.getSelection();
      const range = document.createRange();
      
      range.selectNodeContents(tableElement);
      selection?.removeAllRanges();
      selection?.addRange(range);
    };
    
    const handleCopy = (e: ClipboardEvent) => {
      // Only handle copy if the table contains the current selection
      if (!isTableSelected(tableElement)) return;
      
      // Prevent default copy behavior
      e.preventDefault();
      
      // Create an HTML representation of the table
      const htmlTable = createHTMLTable(tableElement);
      
      // Set clipboard data with HTML format (for Word/Google Docs) and text (fallback)
      e.clipboardData?.setData('text/html', htmlTable);
      e.clipboardData?.setData('text/plain', tableToText(tableElement));
    };
    
    // Check if the table is selected
    const isTableSelected = (table: HTMLTableElement): boolean => {
      const selection = window.getSelection();
      if (!selection || selection.rangeCount === 0) return false;
      
      const range = selection.getRangeAt(0);
      return table.contains(range.commonAncestorContainer);
    };
    
    // Create a clean HTML representation of the table
    const createHTMLTable = (table: HTMLTableElement): string => {
      // Create a copy of the table with minimal styles
      const tableCopy = table.cloneNode(true) as HTMLTableElement;
      
      // Set inline styles for better compatibility with word processors
      return `
        <table border="1" style="border-collapse: collapse; width: 100%;">
          ${tableCopy.innerHTML}
        </table>
      `;
    };
    
    // Convert table to plain text (for fallback)
    const tableToText = (table: HTMLTableElement): string => {
      let result = '';
      
      // Process rows
      const rows = table.querySelectorAll('tr');
      rows.forEach(row => {
        const cells = row.querySelectorAll('th, td');
        const rowTexts: string[] = [];
        
        cells.forEach(cell => {
          rowTexts.push(cell.textContent || '');
        });
        
        result += rowTexts.join('\t') + '\n';
      });
      
      return result;
    };
    
    // Add event listeners
    tableElement.addEventListener('click', handleTableClick);
    document.addEventListener('copy', handleCopy);
    
    // Clean up event listeners
    return () => {
      tableElement.removeEventListener('click', handleTableClick);
      document.removeEventListener('copy', handleCopy);
    };
  }, []);
  
  // Wrap the original table element with our ref, preserving original appearance
  return React.cloneElement(React.Children.only(children) as React.ReactElement, {
    ref: tableRef
  });
};

export default CopyTableWrapper;