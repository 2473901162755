// src/components/Chat/useChat.ts

import { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Conversation, DocumentRef, Message, ThemeType } from '../types';
import { fetchConversations, deleteConversation as deleteConversationUtil, fetchConversationMessages } from '../utils';
import { getCachedData, setCachedData, conversationsCache, messagesCache } from '../cacheUtils';
import { streamingApiCall, apiCall } from '../../apiWrapper';

const API_URL = process.env.REACT_APP_API_URL;

export const useChat = (theme: ThemeType, setTheme: (theme: ThemeType) => void, onOpenAnalytics: () => void) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [currentConversation, setCurrentConversation] = useState<Conversation | null>(null);
  const [isNewConversation, setIsNewConversation] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(window.innerWidth > 768);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pastedDocuments, setPastedDocuments] = useState<Array<{ id: string, content: string }>>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const cachedConversations = getCachedData('conversations', conversationsCache);
    if (cachedConversations) {
      setConversations(cachedConversations);
    }
    fetchConversations(setConversations, setError);
  }, []);

  useEffect(() => {
    if (currentConversation) {
      const cachedMessages = getCachedData('messages', messagesCache) || {};
      setCachedData('messages', {
        ...cachedMessages,
        [currentConversation.id]: currentConversation.messages
      }, messagesCache);
    }
  }, [currentConversation?.messages]);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const stopGeneration = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setIsLoading(false);
    }
  }, [abortController]);
  const fetchUpdatedConversation = useCallback(async (conversationId: string) => {
    try {
      const response = await apiCall<{ conversation: { id: string; title: string } }>(`${API_URL}/conversations/${conversationId}`);
      if (response && response.conversation && response.conversation.title) {
        setConversations(prevConversations =>
          prevConversations.map(conv =>
            conv.id === conversationId ? { ...conv, title: response.conversation.title } : conv
          )
        );
        setCurrentConversation(prevConv => 
          prevConv && prevConv.id === conversationId ? { ...prevConv, title: response.conversation.title } : prevConv
        );
      }
    } catch (error) {
      console.error('Error fetching updated conversation:', error);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const createNewConversation = () => {
    const newConversation: Conversation = { id: 'temp-id', messages: [], title: 'New Conversation', updated_at: new Date().toISOString() };
    setCurrentConversation(newConversation);
    setIsNewConversation(true);
    setConversations(prevConversations => [newConversation, ...prevConversations]);
  };

  const handleConversationSelect = useCallback(async (conversation: Conversation) => {
    setIsLoading(true);
    try {
      const cachedMessages = getCachedData('messages', messagesCache);
      if (cachedMessages && cachedMessages[conversation.id]) {
        setCurrentConversation({ ...conversation, messages: cachedMessages[conversation.id] });
      } else {
        const messages = await fetchConversationMessages(conversation.id);
        setCurrentConversation({ ...conversation, messages });
        const newCachedMessages = { ...(cachedMessages || {}), [conversation.id]: messages };
        setCachedData('messages', newCachedMessages, messagesCache);
      }
      setIsNewConversation(false);
    } catch (error) {
      console.error('Error fetching conversation messages:', error);
      setError('Failed to load conversation messages.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSend = async (complete_input: string, input: string, tempFiles: Array<{ name: string, type: string, url: string }>, documents: DocumentRef[]) => {
    if (isLoading) return;
  
    console.log('Sending document:', documents);
  
    // Créer un nouveau AbortController pour cette requête
    const controller = new AbortController();
    setAbortController(controller);
  
    if (documents.length > 0) {
      // Remove the {document:id} patterns from the input if the id is in documents
      const documentIds = documents.map(doc => doc.id);
      const pattern = new RegExp(`{document:(${documentIds.join('|')})}`, 'g');
      input = input.replace(pattern, '');
    }
  
    if (input.trim() || tempFiles.length > 0 || documents.length > 0) {
      const newUserMessage: Message = { 
        role: 'user', 
        text: input, 
        files: tempFiles,
        document_refs: documents
      };
  
      try {
        setIsLoading(true);
        console.log('Starting to send message...');
  
        if (!currentConversation) {
          createNewConversation();
        }
  
        let updatedConversation: Conversation = currentConversation
          ? { ...currentConversation, messages: [...currentConversation.messages, newUserMessage], updated_at: new Date().toISOString() }
          : { id: 'temp-id', messages: [newUserMessage], title: 'New Conversation', updated_at: new Date().toISOString() };
  
        setCurrentConversation(updatedConversation);
        setIsNewConversation(!currentConversation || currentConversation.id === 'temp-id');
  
        console.log('Sending API request...');
        console.log('document_refs:', documents);
        
        const response = await streamingApiCall(`${API_URL}/chat`, {
          method: 'POST',
          body: JSON.stringify({
            conversation_id: isNewConversation ? null : currentConversation?.id,
            message: complete_input,
            files: tempFiles,
            document_refs: documents
          }),
          signal: controller.signal // Ajout du signal pour l'interruption
        });
  
        console.log('Response received:', response);
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const reader = response.body?.getReader();
        if (!reader) {
          throw new Error('Failed to get reader from response body');
        }
  
        console.log('Starting to read response stream...');
  
        const decoder = new TextDecoder();
        let botMessage: Message = { role: 'assistant', text: '' };
        let newConversationId: string | null = null;
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
  
          const chunk = decoder.decode(value, { stream: true });
          console.log('Received chunk:', chunk);
          const lines = chunk.split('\n').filter(line => line.trim() !== '');
  
          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(5).trim();
  
              if (data === '[DONE]') {
                console.log('Stream complete');
                setIsLoading(false);
                break;
              }
  
              try {
                const messageJson = JSON.parse(data);
  
                // Handle confirmation messages
                if (messageJson.confirmation) {
                  botMessage = {
                    ...botMessage,
                    confirmation: {
                      tool: messageJson.confirmation,
                      message: messageJson.content
                    }
                  };
                }
  
                if (messageJson.content && messageJson.content === '[DONE]') {
                  console.log('Stream complete');
                  setIsLoading(false);
                }
  
                if (messageJson.content && messageJson.content !== '[DONE]') {
                  botMessage.text += messageJson.content;
                }
  
                if (messageJson.image_url) {
                  botMessage.files = botMessage.files || [];
                  botMessage.files.push({
                    name: `generated_image_${botMessage.files.length + 1}.webp`,
                    type: 'image/webp',
                    url: messageJson.image_url[0],
                    justUploaded: true,
                  });
                }
  
                if (!messageJson.scraped && messageJson.url) {
                  botMessage.searchResults = botMessage.searchResults || [];
                  console.log("Found1 :", messageJson.websiteTitle, messageJson.url);
                  botMessage.searchResults.push({
                    title: messageJson.websiteTitle,
                    url: messageJson.url
                  });
                }
  
                // Handle confirmation messages
                if (messageJson.confirmation) {
                  botMessage.confirmation = {
                    tool: messageJson.confirmation,
                    message: messageJson.content
                  }
                }
  
                if (!newConversationId && messageJson.conversation_id) {
                  newConversationId = messageJson.conversation_id;
                }
  
                if (messageJson.title) {
                  updatedConversation.title = messageJson.title;
                  break;
                }
  
                // Update the conversation with the latest chunk
                setCurrentConversation(prevConversation => {
                  if (!prevConversation) {
                    return {
                      id: newConversationId || 'temp-id',
                      messages: [newUserMessage, { ...botMessage }],
                      title: updatedConversation.title,
                      updated_at: new Date().toISOString()
                    };
                  }
                  const updatedMessages = [...prevConversation.messages];
                  if (updatedMessages[updatedMessages.length - 1].role === 'assistant') {
                    updatedMessages[updatedMessages.length - 1] = { ...botMessage };
                  } else {
                    updatedMessages.push({ ...botMessage });
                  }
                  return {
                    ...prevConversation,
                    id: newConversationId || prevConversation.id,
                    messages: updatedMessages,
                    title: updatedConversation.title,
                  };
                });
              } catch (error) {
                console.error('Error processing message:', error, 'Raw data:', data);
              }
            }
          }
        }
  
        // Final update after streaming is complete
        setCurrentConversation(prevConversation => {
          if (!prevConversation) {
            return {
              id: newConversationId || 'temp-id',
              messages: [newUserMessage, botMessage],
              title: updatedConversation.title,
              updated_at: new Date().toISOString()
            };
          }
          return {
            ...prevConversation,
            id: newConversationId || prevConversation.id,
            messages: [...prevConversation.messages.slice(0, -1), botMessage],
            title: updatedConversation.title,
          };
        });
  
        setConversations(prevConversations => {
          const finalUpdatedConversation = {
            id: newConversationId || currentConversation?.id || 'temp-id',
            messages: [...(currentConversation?.messages || []), newUserMessage, botMessage],
            title: updatedConversation.title,
            updated_at: new Date().toISOString()
          };
        
          return prevConversations.map(conv =>
            conv.id === finalUpdatedConversation.id || conv.id === 'temp-id'
              ? finalUpdatedConversation
              : conv
          );
        });
  
        setIsNewConversation(false);
  
      } catch (err: unknown) {
        if (err instanceof Error) {
          if (err.message.includes('401')) {
            navigate('/login');
          }
          console.error('Error in chat:', err);
          setError("Failed to get bot response.");
        } else {
          console.error('Unknown error:', err);
          setError("An unknown error occurred.");
        }
      } finally {
        setAbortController(null);
        setIsLoading(false);
        setPastedDocuments([]);
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
      }
    }
  };

  const handleConfirmation = async (tool: string) => {
    if (currentConversation && currentConversation.messages.length > 0) {
      const lastMessage = currentConversation.messages[currentConversation.messages.length - 1];
      if (lastMessage.confirmation) {
        // Remove the confirmation message
        const updatedMessages = currentConversation.messages.slice(0, -1);
        setCurrentConversation(prev => prev ? {
          ...prev,
          messages: updatedMessages
        } : null);

        // Call the appropriate tool based on confirmation
        if (tool === 'generate_image') {
          // Handle image generation
          await handleSend('Generate image as confirmed', '', [], []);
        } else if (tool === 'search_online') {
          // Handle online search
          await handleSend('Search online as confirmed', '', [], []);
        }
      }
    }
  };

  const deleteConversation = (id: string) => {
    deleteConversationUtil(id, setConversations, setCurrentConversation, setError);
  };

  const handlePastedDocument = (document: { id: string, content: string }) => {
    setPastedDocuments(prev => [...prev, document]);
  };

  const removePastedDocument = (id: string) => {
    setPastedDocuments(prev => prev.filter(doc => doc.id !== id));
  };

  return {
    conversations,
    currentConversation,
    isNewConversation,
    isSidebarOpen,
    isLoading,
    error,
    theme,
    chatContainerRef,
    handleConversationSelect,
    createNewConversation,
    deleteConversation,
    handleSend,
    toggleSidebar,
    setIsSidebarOpen,
    setTheme,
    pastedDocuments,
    handlePastedDocument,
    removePastedDocument,
    onOpenAnalytics,
    fetchUpdatedConversation,
    handleConfirmation,
    stopGeneration,
  };
};