// src/components/MessageList/components/DocumentPreviews.tsx
import React from 'react';
import { DocumentRef } from '../../types';
import DocumentPreview from './DocumentPreview'

interface DocumentPreviewsProps {
  documentRefs: DocumentRef[] | undefined;
  msgIndex: number;
}

export function DocumentPreviews({ documentRefs, msgIndex }: DocumentPreviewsProps) {
  if (!documentRefs || documentRefs.length === 0) return null;

  return (
    <div className="document-previews">
      {documentRefs.map((doc, docIndex) => (
        doc.type === 'image' ? (
          <img 
            key={`msg-${msgIndex}-doc-${docIndex}-${doc.id || docIndex}`}
            src={`data:${doc.mime_type};base64,${doc.content}`} 
            alt={doc.name || 'Uploaded image'} 
            className="document-preview-image"
          />
        ) : (
          <DocumentPreview 
            key={`msg-${msgIndex}-doc-${docIndex}-${doc.id || docIndex}`} 
            document={doc} 
          />
        )
      ))}
    </div>
  );
}