// src/utils/debugRenders.tsx
import React from 'react';

const DEBUG = process.env.NODE_ENV === 'development';
let lastRender = Date.now();

export const withRenderLogging = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  componentName: string
): React.FC<P> => {
  const WithRenderLogging: React.FC<P> = (props) => {
    if (!DEBUG) return <WrappedComponent {...props} />;

    const now = Date.now();
    const timeSinceLastRender = now - lastRender;
    console.log(`${componentName} rendered after ${timeSinceLastRender}ms`, {
      props: Object.keys(props),
      timestamp: new Date(now).toISOString()
    });
    lastRender = now;
    
    return <WrappedComponent {...props} />;
  };

  WithRenderLogging.displayName = `WithRenderLogging(${componentName})`;
  return WithRenderLogging;
};

export const useRenderLogging = (componentName: string): void => {
  React.useEffect(() => {
    if (!DEBUG) return;

    const now = Date.now();
    const timeSinceLastRender = now - lastRender;
    console.log(`${componentName} rendered after ${timeSinceLastRender}ms`, {
      timestamp: new Date(now).toISOString()
    });
    lastRender = now;
  });
};