// src/components/cacheUtils.ts

import { Conversation, Message } from "./types";

const MAX_CACHE_SIZE = 4 * 1024 * 1024; // 4MB limit, adjust as needed

class LRUCache<T> {
  private cache: Map<string, T>;
  private maxSize: number;
  private currentSize: number;

  constructor(maxSize: number) {
    this.cache = new Map();
    this.maxSize = maxSize;
    this.currentSize = 0;
  }

  set(key: string, value: T): void {
    const valueSize = JSON.stringify(value).length;
    
    if (valueSize > this.maxSize) {
      console.warn(`Item ${key} is too large to cache`);
      return;
    }

    if (this.cache.has(key)) {
      this.currentSize -= JSON.stringify(this.cache.get(key)).length;
    }

    while (this.currentSize + valueSize > this.maxSize && this.cache.size > 0) {
      const oldestKey = this.cache.keys().next().value;
      if (oldestKey !== undefined) {
        const oldestValue = this.cache.get(oldestKey);
        if (oldestValue !== undefined) {
          this.currentSize -= JSON.stringify(oldestValue).length;
          this.cache.delete(oldestKey);
        }
      } else { break; }
    }

    this.cache.set(key, value);
    this.currentSize += valueSize;
  }

  get(key: string): T | undefined {
    const value = this.cache.get(key);
    if (value !== undefined) {
      this.cache.delete(key);
      this.cache.set(key, value);
    }
    return value;
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  clear(): void {
    this.cache.clear();
    this.currentSize = 0;
  }
}

const conversationsCache = new LRUCache<Conversation[]>(MAX_CACHE_SIZE);
const messagesCache = new LRUCache<{ [key: string]: Message[] }>(MAX_CACHE_SIZE);

export const getCachedData = <T>(key: string, cache: LRUCache<T>): T | null => {
  const data = cache.get(key);
  return data !== undefined ? data : null;
};

export const setCachedData = <T>(key: string, data: T, cache: LRUCache<T>): void => {
  try {
    cache.set(key, data);
  } catch (error) {
    console.warn(`Failed to cache data for key ${key}:`, error);
  }
};

export { conversationsCache, messagesCache };