// AnalyticsOverlay.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { User, Settings, BarChart2, LogOut, X, Users, Filter, Calendar } from 'lucide-react';
import { apiCall } from '../../../../apiWrapper';
import './AnalyticsOverlay.css';

const API_URL = process.env.REACT_APP_API_URL;

interface AnalyticsOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TokenUsageResponse {
  token_usage: TokenUsageData[];
}

interface UserData {
  email: string;
}

interface TokenUsageData {
  date: string;
  input_tokens: number;
  output_tokens: number;
  cache_creation_input_tokens: number;
  cached_tokens: number;
  models_used: string[];
  tasks: string[];
  conversation_count: number;
  users: string[];
  per_model: {
    [modelName: string]: {
      input_tokens: number;
      output_tokens: number;
      cache_creation_input_tokens: number;
      cached_tokens: number;
      conversation_count: number;
      tasks: string[];
    }
  };
}

interface UserNameMapping {
  [email: string]: string;
}

interface ModelPricing {
  input_tokens: number;
  output_tokens: number;
  cache_creation_input_tokens: number;
  cached_tokens: number;
}

// Add these interfaces after the existing ones
interface TableColumn {
  key: string;
  label: string;
  visible: boolean;
  format?: (value: any) => string | number;
}

interface TableData {
  date: string;
  input_tokens: number;
  output_tokens: number;
  cached_tokens: number;
  cache_creation_tokens: number;
  total_tokens: number;
  input_cost: number;
  output_cost: number;
  cache_cost: number;
  total_cost: number;
  conversation_count: number;
}


const MODEL_PRICING: { [key: string]: ModelPricing } = {
  'claude-3-5-sonnet-20241022': {
    input_tokens: 0.000003,
    output_tokens: 0.000015,
    cache_creation_input_tokens: 0.00000375,
    cached_tokens: 0.0000003
  },
  'claude-3-5-sonnet-20240620': {
    input_tokens: 0.000003,
    output_tokens: 0.000015,
    cache_creation_input_tokens: 0.00000375,
    cached_tokens: 0.0000003
  },
  'gpt-4o-2024-08-06': {
    input_tokens: 0.0000025,
    output_tokens: 0.00001,
    cache_creation_input_tokens: 0,
    cached_tokens: 0.00000125,
  }
};

const USER_NAME_MAPPING: UserNameMapping = {
  'arochet@tw3partners.com': 'André-Louis Rochet',
  'contact@tw3partners.com': 'Contact TW3',
  'lemaistre.paul26@gmail.com': 'Paul Lemaistre',
  'l.appourchaux@tw3partners.com': 'Léo',
  'aprocoli@tw3partners.com': 'Angela Procoli',
  'fvrochet@tw3partners.com': 'François Rochet',
  'd.soeiro-vuong@tw3partners.com': 'David'
};

const getDisplayName = (email: string): string => {
  return USER_NAME_MAPPING[email] || email;
};

const calculateCosts = (data: TokenUsageData[], model: string | 'all'): {
  date: string;
  totalCost: number;
  inputCost: number;
  outputCost: number;
  cacheCost: number;
}[] => {
  return data.map(entry => {
    let inputCost = 0;
    let outputCost = 0;
    let cacheCost = 0;
    let cacheCreationCost = 0;

    if (model === 'all') {
      // Calculate costs for all models
      Object.entries(entry.per_model).forEach(([modelName, modelData]) => {
        if (MODEL_PRICING[modelName]) {
          inputCost += modelData.input_tokens * MODEL_PRICING[modelName].input_tokens;
          outputCost += modelData.output_tokens * MODEL_PRICING[modelName].output_tokens;
          cacheCost += modelData.cached_tokens * MODEL_PRICING[modelName].cached_tokens;
          cacheCreationCost += modelData.cache_creation_input_tokens * MODEL_PRICING[modelName].cache_creation_input_tokens;
        }
      });
    } else if (MODEL_PRICING[model] && entry.per_model[model]) {
      // Calculate costs for specific model
      const modelData = entry.per_model[model];
      inputCost = modelData.input_tokens * MODEL_PRICING[model].input_tokens;
      outputCost = modelData.output_tokens * MODEL_PRICING[model].output_tokens;
      cacheCost = modelData.cached_tokens * MODEL_PRICING[model].cached_tokens;
      cacheCreationCost = modelData.cache_creation_input_tokens * MODEL_PRICING[model].cache_creation_input_tokens;
    }

    return {
      date: entry.date,
      totalCost: inputCost + outputCost + cacheCost + cacheCreationCost,
      inputCost,
      outputCost,
      cacheCost: cacheCost + cacheCreationCost
    };
  });
};

const AnalyticsOverlay: React.FC<AnalyticsOverlayProps> = ({ isOpen, onClose }) => {
  const [selectedUser, setSelectedUser] = useState<string | 'team'>('team');
  const [users, setUsers] = useState<UserData[]>([]);
  const [analyticsData, setAnalyticsData] = useState<TokenUsageData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState<string>('all');
  const [selectedRange, setSelectedRange] = useState<'24h' | '7d' | '30d' | 'custom'>('24h');
  const [startDate, setStartDate] = useState<string>(
    new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().split('T')[0]
  );
  const [groupBy, setGroupBy] = useState<'hour' | 'day' | 'week' | 'month'>('hour');

  const AVAILABLE_MODELS = [
    'claude-3-5-sonnet-20241022',
    'claude-3-5-sonnet-20240620',
    'gpt-4o-2024-08-06',
  ];

  const CustomTable: React.FC<{
    data: TableData[];
    columns: TableColumn[];
    onToggleColumn: (key: string) => void;
  }> = ({ data, columns, onToggleColumn }) => {
    const calculateAverage = (key: string): number => {
      if (data.length === 0) return 0;
      const sum = data.reduce((acc, row) => acc + (row[key as keyof TableData] as number), 0);
      return sum / data.length;
    };
  
    const calculateTotal = (key: string): number => {
      return data.reduce((acc, row) => acc + (row[key as keyof TableData] as number), 0);
    };
  
    return (
      <div className="custom-table-container">
        <div className="table-controls">
          <h3>Customize Columns</h3>
          <div className="column-toggles">
            {columns.map(col => (
              <label 
                key={col.key} 
                className={`column-toggle ${col.visible ? 'active' : ''}`}
              >
                <input
                  type="checkbox"
                  checked={col.visible}
                  onChange={() => onToggleColumn(col.key)}
                />
                <span>{col.label}</span>
              </label>
            ))}
          </div>
        </div>
  
        <div className="table-wrapper">
          <table className="analytics-table">
            <thead>
              <tr>
                {columns.filter(col => col.visible).map(col => (
                  <th key={col.key}>{col.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {columns.filter(col => col.visible).map(col => (
                    <td key={col.key}>
                      {col.format ? col.format(row[col.key as keyof TableData]) : row[col.key as keyof TableData]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                {columns.filter(col => col.visible).map(col => (
                  <td key={col.key}>
                    <strong>
                      {col.key === 'date' ? 'Average:' : 
                        col.format ? col.format(calculateAverage(col.key)) : 
                        calculateAverage(col.key).toFixed(2)}
                    </strong>
                  </td>
                ))}
              </tr>
              <tr>
                {columns.filter(col => col.visible).map(col => (
                  <td key={col.key}>
                    <strong>
                      {col.key === 'date' ? 'Total:' : 
                        col.format ? col.format(calculateTotal(col.key)) : 
                        calculateTotal(col.key).toFixed(2)}
                    </strong>
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  };

  const [tableColumns, setTableColumns] = useState<TableColumn[]>([
    { 
        key: 'date', 
        label: 'Date', 
        visible: true,
        format: (value: string) => {
            const date = new Date(value);
            return date.toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }).replace(',', '');
        }
    },
    // ... rest of the columns remain the same
    { key: 'input_tokens', label: 'Input Tokens', visible: true, 
      format: (value: number) => value.toLocaleString() },
    { key: 'output_tokens', label: 'Output Tokens', visible: true, 
      format: (value: number) => value.toLocaleString() },
    { key: 'cached_tokens', label: 'Cached Tokens', visible: true, 
      format: (value: number) => value.toLocaleString() },
    { key: 'cache_creation_tokens', label: 'Cache Creation Tokens', visible: false, 
      format: (value: number) => value.toLocaleString() },
    { key: 'total_tokens', label: 'Total Tokens', visible: true, 
      format: (value: number) => value.toLocaleString() },
    { key: 'input_cost', label: 'Input Cost', visible: true, 
      format: (value: number) => `$${value.toFixed(4)}` },
    { key: 'output_cost', label: 'Output Cost', visible: true, 
      format: (value: number) => `$${value.toFixed(4)}` },
    { key: 'cache_cost', label: 'Cache Cost', visible: false, 
      format: (value: number) => `$${value.toFixed(4)}` },
    { key: 'total_cost', label: 'Total Cost', visible: true, 
      format: (value: number) => `$${value.toFixed(4)}` },
    { key: 'conversation_count', label: 'Conversations', visible: true, 
      format: (value: number) => value.toLocaleString() },
  ]);

  const handleToggleColumn = (key: string) => {
    setTableColumns(prev => prev.map(col => 
      col.key === key ? { ...col, visible: !col.visible } : col
    ));
  };  

  const validateDateRange = (start: string, end: string): boolean => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const today = new Date();

    if (startDate > endDate) {
      setDateError("Start date cannot be after end date");
      return false;
    }

    if (endDate > today) {
      setDateError("End date cannot be in the future");
      return false;
    }

    setDateError(null);
    return true;
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    if (validateDateRange(newStartDate, endDate)) {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (validateDateRange(startDate, newEndDate)) {
      setEndDate(newEndDate);
    }
  };

  const getDateRange = (range: '24h' | '7d' | '30d' | 'custom'): { start: Date; end: Date } => {
    let endTime = new Date();
    let startTime = new Date();

    switch (range) {
      case '24h':
        startTime.setHours(endTime.getHours() - 24);
        break;
      case '7d':
        startTime.setDate(endTime.getDate() - 7);
        break;
      case '30d':
        startTime.setDate(endTime.getDate() - 30);
        break;
      case 'custom':
        startTime = new Date(`${startDate}T00:00:00`);
        endTime = new Date(`${endDate}T23:59:59`);
        break;
    }

    return { start: startTime, end: endTime };
  };

  useEffect(() => {
    const currentDate = new Date();
    
    switch (selectedRange) {
      case '24h':
        setStartDate(new Date(currentDate.getTime() - 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
        break;
      case '7d':
        setStartDate(new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
        break;
      case '30d':
        setStartDate(new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
        break;
      case 'custom':
        return;
    }
    
    setEndDate(currentDate.toISOString().split('T')[0]);
  }, [selectedRange]);

  const groupData = (data: TokenUsageData[], grouping: 'hour' | 'day' | 'week' | 'month'): TokenUsageData[] => {
    const grouped = new Map<string, TokenUsageData>();
  
    data.forEach(item => {
      const date = new Date(item.date);
      let key: string;
  
      switch (grouping) {
        case 'hour':
          key = date.toISOString().slice(0, 13) + ':00:00Z';
          break;
        case 'day':
          key = date.toISOString().slice(0, 10);
          break;
        case 'week':
          const weekStart = new Date(date);
          weekStart.setDate(date.getDate() - date.getDay());
          key = weekStart.toISOString().slice(0, 10);
          break;
        case 'month':
          key = date.toISOString().slice(0, 7) + '-01';
          break;
      }
  
      if (!grouped.has(key)) {
        grouped.set(key, {
          date: key,
          input_tokens: 0,
          output_tokens: 0,
          cache_creation_input_tokens: 0,
          cached_tokens: 0,
          models_used: [],
          tasks: [],
          conversation_count: 0,
          users: [],
          per_model: {}
        });
      }
  
      const existing = grouped.get(key)!;
      existing.input_tokens += item.input_tokens;
      existing.output_tokens += item.output_tokens;
      existing.cache_creation_input_tokens += item.cache_creation_input_tokens;
      existing.cached_tokens += item.cached_tokens;
      existing.conversation_count += item.conversation_count;
      
      existing.models_used = Array.from(new Set([...existing.models_used, ...item.models_used]));
      existing.tasks = Array.from(new Set([...existing.tasks, ...item.tasks]));
      existing.users = Array.from(new Set([...existing.users, ...item.users]));
  
      Object.entries(item.per_model).forEach(([model, modelData]) => {
        if (!existing.per_model[model]) {
          existing.per_model[model] = {
            input_tokens: 0,
            output_tokens: 0,
            cache_creation_input_tokens: 0,
            cached_tokens: 0,
            conversation_count: 0,
            tasks: []
          };
        }
        const existingModel = existing.per_model[model];
        existingModel.input_tokens += modelData.input_tokens;
        existingModel.output_tokens += modelData.output_tokens;
        existingModel.cache_creation_input_tokens += modelData.cache_creation_input_tokens;
        existingModel.cached_tokens += modelData.cached_tokens;
        existingModel.conversation_count += modelData.conversation_count;
        existingModel.tasks = Array.from(new Set([...existingModel.tasks, ...modelData.tasks]));
      });
    });
  
    return Array.from(grouped.values()).sort((a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isOpen) return;
      
      setIsLoading(true);
      try {
        const response = await apiCall<TokenUsageResponse>(`${API_URL}/analytics/token_usage`);
        setAnalyticsData(response.token_usage);
        setUsers(extractUniqueUsers(response.token_usage));
        setError(null);
      } catch (err) {
        console.error('Error fetching analytics data:', err);
        setError('Failed to load analytics data');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [isOpen]);

  const extractUniqueUsers = (data: TokenUsageData[]): UserData[] => {
    const uniqueEmails = new Set<string>();
    const users: UserData[] = [];
    
    data.forEach(entry => {
      entry.users.forEach(email => {
        if (!uniqueEmails.has(email)) {
          uniqueEmails.add(email);
          users.push({ email });
        }
      });
    });
    
    return users;
  };

  const filteredData = useMemo(() => {
    if (!Array.isArray(analyticsData)) return [];
    
    let filtered = analyticsData;
    
    if (selectedUser !== 'team') {
      filtered = filtered.filter(item => item.users.includes(selectedUser));
    }
    
    if (selectedModel !== 'all') {
      filtered = filtered.filter(item => item.models_used.includes(selectedModel));
    }
  
    const { start, end } = getDateRange(selectedRange);
    filtered = filtered.filter(item => {
      const date = new Date(item.date);
      return date >= start && date <= end;
    });
  
    return groupData(filtered, groupBy);
  }, [analyticsData, selectedModel, selectedUser, selectedRange, startDate, endDate, groupBy]);

  const aggregateStats = useMemo(() => {
    if (!Array.isArray(filteredData)) return {
      totalInputTokens: 0,
      totalOutputTokens: 0,
      totalCacheCreationTokens: 0,
      totalCachedTokens: 0,
      totalConversations: 0,
      totalInputCost: 0,
      totalOutputCost: 0,
      totalCacheCost: 0
    };
  
    return filteredData.reduce((acc, curr) => {
      if (selectedModel !== 'all') {
        // When filtering for a specific model, only count that model's data
        const modelData = curr.per_model[selectedModel];
        if (modelData && MODEL_PRICING[selectedModel]) {
          const pricing = MODEL_PRICING[selectedModel];
          return {
            ...acc,
            totalInputTokens: acc.totalInputTokens + modelData.input_tokens,
            totalOutputTokens: acc.totalOutputTokens + modelData.output_tokens,
            totalCacheCreationTokens: acc.totalCacheCreationTokens + modelData.cache_creation_input_tokens,
            totalCachedTokens: acc.totalCachedTokens + modelData.cached_tokens,
            totalConversations: acc.totalConversations + modelData.conversation_count,
            totalInputCost: acc.totalInputCost + (modelData.input_tokens * pricing.input_tokens),
            totalOutputCost: acc.totalOutputCost + (modelData.output_tokens * pricing.output_tokens),
            totalCacheCost: acc.totalCacheCost + 
              (modelData.cached_tokens * pricing.cached_tokens) +
              (modelData.cache_creation_input_tokens * pricing.cache_creation_input_tokens)
          };
        }
        return acc;
      }
  
      // For all models, sum up the total tokens directly without proportional division
      return {
        ...acc,
        totalInputTokens: acc.totalInputTokens + curr.input_tokens,
        totalOutputTokens: acc.totalOutputTokens + curr.output_tokens,
        totalCacheCreationTokens: acc.totalCacheCreationTokens + curr.cache_creation_input_tokens,
        totalCachedTokens: acc.totalCachedTokens + curr.cached_tokens,
        totalConversations: acc.totalConversations + curr.conversation_count,
        totalInputCost: acc.totalInputCost + Object.entries(curr.per_model).reduce((cost, [model, data]) => {
          return cost + (MODEL_PRICING[model] ? data.input_tokens * MODEL_PRICING[model].input_tokens : 0);
        }, 0),
        totalOutputCost: acc.totalOutputCost + Object.entries(curr.per_model).reduce((cost, [model, data]) => {
          return cost + (MODEL_PRICING[model] ? data.output_tokens * MODEL_PRICING[model].output_tokens : 0);
        }, 0),
        totalCacheCost: acc.totalCacheCost + Object.entries(curr.per_model).reduce((cost, [model, data]) => {
          if (!MODEL_PRICING[model]) return cost;
          return cost + 
            (data.cached_tokens * MODEL_PRICING[model].cached_tokens) +
            (data.cache_creation_input_tokens * MODEL_PRICING[model].cache_creation_input_tokens);
        }, 0)
      };
    }, {
      totalInputTokens: 0,
      totalOutputTokens: 0,
      totalCacheCreationTokens: 0,
      totalCachedTokens: 0,
      totalConversations: 0,
      totalInputCost: 0,
      totalOutputCost: 0,
      totalCacheCost: 0
    });
  }, [filteredData, selectedModel, MODEL_PRICING]);

  if (!isOpen) return null;

  return (
    <div className="analytics-dashboard-container">
      <button className="close-analytics-button" onClick={onClose}>
        <X size={18} />
      </button>

      <div className="analytics-sidebar">
        <div className="analytics-team-selector">
          <button 
            className={selectedUser === 'team' ? 'active' : ''} 
            onClick={() => setSelectedUser('team')}
          >
            <Users size={18} /> Team Overview
          </button>
          {Array.from(new Set(analyticsData?.flatMap(entry => entry.users) || [])).map(email => (
            <button
              key={email}
              className={selectedUser === email ? 'active' : ''}
              onClick={() => setSelectedUser(email)}
            >
              <User size={18} /> {getDisplayName(email)}
            </button>
          ))}
        </div>

        <div className="analytics-model-filter">
          <h3><Filter size={18} /> Model Filter</h3>
          <select 
            value={selectedModel} 
            onChange={(e) => setSelectedModel(e.target.value)}
          >
            <option value="all">All Models</option>
            {AVAILABLE_MODELS.map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </div>

        <div className="analytics-date-range">
          <h3><Calendar size={18} /> Date Range</h3>
          <select 
            value={selectedRange} 
            onChange={(e) => setSelectedRange(e.target.value as '24h' | '7d' | '30d' | 'custom')}
          >
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
            <option value="custom">Custom Range</option>
          </select>
          
          {selectedRange === 'custom' && (
            <div className="custom-date-picker">
              <div className="date-input-group">
                <label>Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  max={endDate}
                />
              </div>
              <div className="date-input-group">
                <label>End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  min={startDate}
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>
              {dateError && (
                <div className="date-error-message">
                  {dateError}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="analytics-grouping">
          <h3><BarChart2 size={18} /> Group By</h3>
          <select
            value={groupBy}
            onChange={(e) => setGroupBy(e.target.value as 'hour' | 'day' | 'week' | 'month')}
          >
            <option value="hour">Hourly</option>
            <option value="day">Daily</option>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
          </select>
        </div>
      </div>

      <div className="analytics-main-content">
        <h1>
          {selectedUser === 'team' 
            ? 'Team Analytics' 
            : `${getDisplayName(selectedUser)}'s Analytics`
          }
        </h1>
        
        {isLoading ? (
          <div className="analytics-loading">Loading analytics...</div>
        ) : error ? (
          <div className="analytics-error">{error}</div>
        ) : (
          <>
            <div className="analytics-chart-container">
              <h2>Token Usage Over Time</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={filteredData}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="input_tokens" 
                    stroke="#8884d8" 
                    name="Input Tokens" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="output_tokens" 
                    stroke="#82ca9d" 
                    name="Output Tokens" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="cached_tokens" 
                    stroke="#ffc658" 
                    name="Cached Tokens" 
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="analytics-summary-stats">
              <div className="analytics-stat-item">
                <div className="analytics-stat-value">
                  {Math.round(aggregateStats.totalInputTokens).toLocaleString()}
                </div>
                <div className="analytics-stat-label">Total Input Tokens</div>
                <div className="analytics-stat-cost">
                  ${aggregateStats.totalInputCost.toFixed(2)}
                </div>
              </div>
              <div className="analytics-stat-item">
                <div className="analytics-stat-value">
                  {Math.round(aggregateStats.totalOutputTokens).toLocaleString()}
                </div>
                <div className="analytics-stat-label">Total Output Tokens</div>
                <div className="analytics-stat-cost">
                  ${aggregateStats.totalOutputCost.toFixed(2)}
                </div>
              </div>
              <div className="analytics-stat-item">
                <div className="analytics-stat-value">
                  {Math.round(aggregateStats.totalCachedTokens).toLocaleString()}
                </div>
                <div className="analytics-stat-label">Total Cached Tokens</div>
                <div className="analytics-stat-cost">
                  ${aggregateStats.totalCacheCost.toFixed(2)}
                </div>
              </div>
              <div className="analytics-stat-item">
                <div className="analytics-stat-value">
                  {Math.round(aggregateStats.totalConversations).toLocaleString()}
                </div>
                <div className="analytics-stat-label">Total Conversations</div>
                <div className="analytics-stat-cost">
                  ${(aggregateStats.totalInputCost + aggregateStats.totalOutputCost + aggregateStats.totalCacheCost).toFixed(2)} (total)
                </div>
              </div>
            </div>

            <div className="analytics-chart-container">
              <h2>Cost Analysis Over Time</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={calculateCosts(filteredData, selectedModel)}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value: number) => `$${value.toFixed(4)}`}
                  />
                  <Legend />
                  <Line 
                    type="monotone" 
                    dataKey="inputCost" 
                    stroke="#8884d8" 
                    name="Input Cost" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="outputCost" 
                    stroke="#82ca9d" 
                    name="Output Cost" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="cacheCost" 
                    stroke="#ffc658" 
                    name="Cache Cost" 
                  />
                  <Line 
                    type="monotone" 
                    dataKey="totalCost" 
                    stroke="#ff7300" 
                    name="Total Cost" 
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="analytics-table-section">
              <h2>Detailed Analytics</h2>
              <CustomTable
                data={filteredData.map(item => {
                  const costs = calculateCosts([item], selectedModel)[0];
                  return {
                    date: item.date,
                    input_tokens: item.input_tokens,
                    output_tokens: item.output_tokens,
                    cached_tokens: item.cached_tokens,
                    cache_creation_tokens: item.cache_creation_input_tokens,
                    total_tokens: item.input_tokens + item.output_tokens + item.cached_tokens + item.cache_creation_input_tokens,
                    input_cost: costs.inputCost,
                    output_cost: costs.outputCost,
                    cache_cost: costs.cacheCost,
                    total_cost: costs.totalCost,
                    conversation_count: item.conversation_count
                  };
                })}
                columns={tableColumns}
                onToggleColumn={handleToggleColumn}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsOverlay;