import React, { useRef, useState, useEffect } from 'react';
import MessageList from '../MessageList';
import InputArea from '../InputArea/InputArea';
import { Conversation, Message, DocumentRef, FileMetadata, ThemeType, Project } from '../types';
import WelcomeInput from '../WelcomeInput/WelcomeInput';
interface StopGenerationButtonProps {
  isGenerating: boolean;
  onStop: () => void;
}

const StopGenerationButton: React.FC<StopGenerationButtonProps> = ({ 
  isGenerating, 
  onStop 
}) => {
  if (!isGenerating) return null;
  
  return (
    <button 
      className="absolute right-2 top-2 p-2 rounded bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
      onClick={onStop}
    >
      Stop generating
    </button>
  );
};

interface ChatContentProps {
  currentConversation: Conversation | null;
  isLoading: boolean;
  error: string | null;
  onFilesDropped?: (files: File[]) => void;
  theme: ThemeType;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  handleSend: (completeInput: string, input: string, tempFiles: FileMetadata[], documents: DocumentRef[]) => Promise<void>;
  setTheme: (theme: ThemeType) => void;
  isSidebarOpen: boolean;
  fetchUpdatedConversation: (conversationId: string) => Promise<void>;
  handleConfirmation: (tool: string) => void;
  stopGeneration: () => void;
  isGenerating: boolean; projects: Project[];
  handleProjectSelect: (project: Project) => void;
}

const ChatContent = React.forwardRef<
  { handleFilesDropped: (files: File[]) => void },
  ChatContentProps
>(({
  currentConversation,
  isLoading,
  error,
  theme,
  chatContainerRef,
  handleSend,
  setTheme,
  isSidebarOpen,
  fetchUpdatedConversation,
  handleConfirmation,
  stopGeneration,
  isGenerating,
  projects,
  handleProjectSelect,
}, ref) => {
  const inputAreaRef = useRef<{ handleFileUpload: (file: File) => void }>(null);
  const [pastedDocumentRefs, setPastedDocumentRefs] = useState<DocumentRef[]>([]);
  const messageListRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isAssistantResponding, setIsAssistantResponding] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const showWelcomeInput = !hasInteracted && (!currentConversation || currentConversation.messages.length === 0);
  const welcomeInputRef = useRef<{ handleFileUpload: (file: File) => void }>(null);

  const mapMessages = (messages: any[]): Message[] => {
    console.log('Mapping messages:', messages);
    return messages.map(msg => {
      // Create the base message object
      const mappedMessage: Message = {
        role: msg.role === 'user' ? 'user' : 'assistant',
        text: msg.content || msg.text || '',
        files: msg.files || [],
        searchResults: msg.searchResults || [],
        document_refs: msg.document_refs || [],
        conversationId: currentConversation?.id,
      };
  
      // Explicitly check and add the confirmation object if it exists
      if (msg.confirmation) {
        mappedMessage.confirmation = {
          tool: msg.confirmation.tool,
          message: msg.confirmation.message
        };
      }
  
      console.log('Mapped message:', mappedMessage);
      return mappedMessage;
    });
  };

  const handlePastedDocument = (document: DocumentRef) => {
    console.log('Pasted document:', document);
    setPastedDocumentRefs(prev => [...prev, document]);
  };

  const removePastedDocument = (id: string) => {
    setPastedDocumentRefs(prev => prev.filter(doc => doc.id !== id));
  };

  const handleSendWithDocuments = async (input: string, tempFiles: FileMetadata[]) => {
    console.log('Sending input with pasted documents:', input, pastedDocumentRefs);
    setHasInteracted(true);
    setIsAssistantResponding(true);
    const completeInput = [       
      ...pastedDocumentRefs.map(doc => doc.type === 'image' ? `[Image: ${doc.id}]` : doc.content),
      input
    ].join('\n\n');  
    const fullInput = [
      ...pastedDocumentRefs.map(doc => `{document:${doc.id}}`),
      input
    ].join('\n\n');

    await handleSend(completeInput, fullInput, tempFiles, pastedDocumentRefs);
    
    setPastedDocumentRefs([]); // Clear pasted documents after sending
    setIsAssistantResponding(false);
  };

  const updatePastedDocument = (updatedDocument: DocumentRef) => {
    setPastedDocumentRefs(prev =>
      prev.map(doc => doc.id === updatedDocument.id ? updatedDocument : doc)
    );
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const atBottom = scrollHeight - scrollTop <= clientHeight + 50;
      setShowScrollButton(!atBottom);
    }
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="chat-content">
      <div className="messages-container" ref={messageListRef}>
        <MessageList
          messages={currentConversation ? mapMessages(currentConversation.messages) : []}
          isLoading={isLoading}
          error={error}
          chatContainerRef={messageListRef}
          fetchUpdatedConversation={fetchUpdatedConversation}
          currentConversationId={currentConversation?.id || null}
          theme={theme === "dark" ? "dark" : "light"}
          onConfirm={handleConfirmation}
        />
      </div>
      <div className="input-area-wrapper">
        {showWelcomeInput ? (
          <WelcomeInput
            onSend={handleSend}
            isAssistantResponding={isAssistantResponding}
            pastedDocumentRefs={pastedDocumentRefs}
            handlePastedDocument={handlePastedDocument}
            removePastedDocument={removePastedDocument}
            isSidebarOpen={isSidebarOpen}
            projects={projects} // Nouvelle prop
            onSelectProject={handleProjectSelect} // Nouvelle prop
            ref={welcomeInputRef}
          />
          ) : (
            <InputArea
            ref={inputAreaRef}
            onSend={handleSendWithDocuments}
            isAssistantResponding={isLoading}
            pastedDocumentRefs={pastedDocumentRefs}
            handlePastedDocument={handlePastedDocument}
            removePastedDocument={removePastedDocument}
            updatePastedDocument={updatePastedDocument}
            isSidebarOpen={isSidebarOpen}
            isGenerating={isGenerating}
            stopGeneration={stopGeneration}
            projects={projects} // Ajouté
            onSelectProject={handleProjectSelect} // Ajouté
          />
        )}
      </div>
      {showScrollButton && (
        <button className="scroll-to-bottom-button" onClick={scrollToBottom}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      )}
    </div>
  );
});

export default ChatContent;