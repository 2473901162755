// src/utils/emojiUtils.ts

const emojiCategories = {
  energy: {
    keywords: [
      'energy', 'énergie', 'electricity', 'électricité', 'power', 'edf', 
      'nuclear', 'nucléaire', 'grid', 'réseau', 'distribution', 'production',
      'volt', 'watt', 'megawatt', 'kilowatt'
    ],
    emojis: ['⚡', '🔋', '🔌', '💡', '⚛️', '🏭']
  },
  renewable: {
    keywords: [
      'renewable', 'renouvelable', 'solar', 'solaire', 'wind', 'éolien',
      'hydro', 'hydraulique', 'sustainable', 'durable', 'green', 'vert',
      'clean', 'propre', 'ecological', 'écologique', 'biomass', 'biomasse'
    ],
    emojis: ['☀️', '🌞', '🌪️', '💨', '🌊', '🌱', '♻️', '🌍']
  },
  infrastructure: {
    keywords: [
      'infrastructure', 'network', 'réseau', 'grid', 'smart grid', 'réseau intelligent',
      'installation', 'equipment', 'équipement', 'facility', 'plant', 'centrale',
      'station', 'transformer', 'transformateur'
    ],
    emojis: ['🏗️', '🏢', '🏭', '🔧', '⚙️', '🏪']
  },
  it_systems: {
    keywords: [
      'it', 'system', 'système', 'software', 'logiciel', 'application', 
      'platform', 'plateforme', 'digital', 'numérique', 'cloud', 'data', 'données',
      'computing', 'informatique', 'tech', 'technologie'
    ],
    emojis: ['💻', '🖥️', '🔌', '📱', '☁️', '📊']
  },
  automation: {
    keywords: [
      'automation', 'automatisation', 'smart', 'intelligent', 'iot', 
      'connected', 'connecté', 'device', 'appareil', 'sensor', 'capteur',
      'monitor', 'monitoring', 'surveillance'
    ],
    emojis: ['🤖', '📡', '🔄', '📲', '🎮', '🔍']
  },
  maintenance: {
    keywords: [
      'maintenance', 'repair', 'réparation', 'service', 'support',
      'operation', 'exploitation', 'inspection', 'control', 'contrôle',
      'diagnostic', 'testing', 'test'
    ],
    emojis: ['🔧', '🔨', '🛠️', '⚙️', '📋', '🔍']
  },
  security: {
    keywords: [
      'security', 'sécurité', 'safety', 'sûreté', 'protection', 'cyber',
      'risk', 'risque', 'compliance', 'conformité', 'regulation', 'réglementation'
    ],
    emojis: ['🔒', '🛡️', '🔐', '⚠️', '🔑', '📋']
  },
  environmental: {
    keywords: [
      'environmental', 'environnemental', 'climate', 'climat', 'carbon',
      'carbone', 'emission', 'émission', 'footprint', 'impact',
      'sustainability', 'durabilité'
    ],
    emojis: ['🌍', '🌱', '🌿', '🍃', '♻️', '🌡️']
  },
  innovation: {
    keywords: [
      'innovation', 'research', 'recherche', 'development', 'développement',
      'project', 'projet', 'startup', 'initiative', 'pilot', 'pilote',
      'experiment', 'expérimentation'
    ],
    emojis: ['💡', '🚀', '🔬', '🎯', '⭐', '🔮']
  },
  collaboration: {
    keywords: [
      'collaboration', 'partnership', 'partenariat', 'client', 'customer',
      'service', 'support', 'team', 'équipe', 'communication', 'meeting',
      'réunion'
    ],
    emojis: ['🤝', '👥', '💬', '📊', '🤲', '🌟']
  }
};

// Les fonctions restent les mêmes
function stringSimilarity(str1: string, str2: string): number {
  const s1 = str1.toLowerCase();
  const s2 = str2.toLowerCase();
  
  if (s1.includes(s2) || s2.includes(s1)) {
    return 0.8;
  }
  
  const words1 = s1.split(/\W+/);
  const words2 = s2.split(/\W+/);
  const commonWords = words1.filter(word => words2.includes(word)).length;
  
  return commonWords / Math.max(words1.length, words2.length);
}

export function getProjectEmoji(projectName: string): string {
  const nameLower = projectName.toLowerCase();
  let bestMatch = {
    category: '',
    score: 0
  };

  Object.entries(emojiCategories).forEach(([category, data]) => {
    data.keywords.forEach(keyword => {
      const similarity = stringSimilarity(nameLower, keyword);
      if (similarity > bestMatch.score) {
        bestMatch = {
          category: category,
          score: similarity
        };
      }
    });
  });

  if (bestMatch.score > 0.3) {
    const category = emojiCategories[bestMatch.category as keyof typeof emojiCategories];
    return category.emojis[Math.floor(Math.random() * category.emojis.length)];
  }

  // Emojis de secours adaptés au secteur
  const fallbackEmojis = ['⚡', '💡', '🔧', '🌍', '💻', '🏢', '📊', '🚀'];
  return fallbackEmojis[Math.floor(Math.random() * fallbackEmojis.length)];
}