import React from 'react';
import { User, Settings, BarChart2, LogOut, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface DropdownItemProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick?: () => void;
  isHighlighted?: boolean;
}

interface DropdownMenuProps {
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  onClose: () => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ children, icon, onClick, isHighlighted }) => {
  return (
    <div className={`menu-item-wrapper ${isHighlighted ? 'highlighted' : ''}`}>
      <button className="menu-item" onClick={onClick}>
        <span className="menu-item-icon">{icon}</span>
        <span className="menu-item-text">{children}</span>
      </button>
    </div>
  );
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ onOpenSettings, onOpenAnalytics, onClose }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Appel à l'API pour déconnecter l'utilisateur
      const response = await fetch('/api/auth/logout', {
        method: 'POST',
        credentials: 'include', // Important pour inclure les cookies
      });

      if (response.ok) {
        // Suppression des données locales (localStorage, etc.)
        localStorage.clear();
        sessionStorage.clear();
        
        // Redirection vers la page de login
        navigate('/login');
      } else {
        console.error('Erreur lors de la déconnexion');
      }
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header">
        <h2 className="dropdown-title">User Menu</h2>
        <button className="close-icon" onClick={onClose}>
          <X size={18} />
        </button>
      </div>
      <div className="dropdown-content">
        <DropdownItem icon={<User size={18} />}>My Profile</DropdownItem>
        <DropdownItem icon={<Settings size={18} />} onClick={onOpenSettings}>Settings</DropdownItem>
        <DropdownItem icon={<BarChart2 size={18} />} onClick={onOpenAnalytics}>Analytics</DropdownItem>
        <DropdownItem icon={<LogOut size={18} />} onClick={handleLogout}>Logout</DropdownItem>
      </div>
    </div>
  );
};

export default DropdownMenu;