// src/components/Chat/Chat.tsx

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import ChatHeader from './ChatHeader';
import ChatContent from './ChatContent';
import { useChat } from './useChat';
import { getCachedImage } from '../../imageCache';
import { UserInfo, ThemeType, DocumentRef, Project, FileMetadata, ProjectFile } from '../types';
import '../../App.css';
import '../../themes.css';
import './Chat.css';
import WelcomeInput from '../WelcomeInput/WelcomeInput';

interface ChatProps {
  userInfo: UserInfo | null;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  theme: ThemeType;
  setTheme: (theme: ThemeType) => void;
}

function Chat({ userInfo, onOpenSettings, onOpenAnalytics, theme, setTheme }: ChatProps) {
  const {
    conversations,
    currentConversation,
    isNewConversation,
    isSidebarOpen,
    isLoading,
    error,
    chatContainerRef,
    handleConversationSelect,
    createNewConversation,
    deleteConversation,
    handleSend,
    toggleSidebar,
    setIsSidebarOpen,
    fetchUpdatedConversation,
    handleConfirmation,
    stopGeneration,
  } = useChat(theme, setTheme, onOpenAnalytics);

  const [hasSubmittedQuery, setHasSubmittedQuery] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState<string>('/default-avatar.png');
  const [isDragging, setIsDragging] = useState(false);
  const welcomeInputRef = useRef<{ handleFileUpload: (file: File) => void }>(null);
  const chatContentRef = useRef<{ handleFilesDropped: (files: File[]) => void }>(null);
  const [pastedDocumentRefs, setPastedDocumentRefs] = useState<DocumentRef[]>([]);
  const shouldShowWelcomeInput = !currentConversation?.messages?.length;
  const [projects, setProjects] = useState<Project[]>([]);
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };
  const [isAssistantResponding, setIsAssistantResponding] = useState(false);

  const handleProjectSelect = useCallback((project: Project) => {
    // Logique pour gérer la sélection du projet
    console.log('Selected project:', project);
    // Ajouter votre logique ici
  }, []);

  const handlePastedDocument = useCallback((document: DocumentRef) => {
    setPastedDocumentRefs(prev => [...prev, document]);
  }, []);

  const removePastedDocument = useCallback((id: string) => {
    setPastedDocumentRefs(prev => prev.filter(doc => doc.id !== id));
  }, []);

  const handleWelcomeInputSend = useCallback(
    async (complete_input: string, input: string, tempFiles: FileMetadata[], documents: DocumentRef[]) => {
      await handleSend(complete_input, input, tempFiles, documents);
      setHasSubmittedQuery(true);
    },
    [handleSend]
  );

 
  interface NewProjectData {
    name: string;
    context: string;
    files: ProjectFile[]; 
  }

const handleEditProject = useCallback((project: Project) => {
  setProjects(prev => prev.map(p => 
    p.id === project.id ? { ...p, ...project } : p
  ));
}, []);

const createNewProject = useCallback(async (projectData: NewProjectData) => {
  try {
    const newProject: Project = {
      id: `project-${Date.now()}`,
      name: projectData.name,
      context: projectData.context,
      files: projectData.files, // Déjà des ProjectFile[]
      createdAt: new Date(),
      updatedAt: new Date()
    };

    setProjects(prev => [...prev, newProject]);
  } catch (error) {
    console.error('Error creating project:', error);
  }
}, []);

  const deleteProject = useCallback((id: string) => {
    setProjects(prev => prev.filter(project => project.id !== id));
  }, []);

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Vérifier si on quitte vraiment la zone (pas un élément enfant)
    if (e.currentTarget.contains(e.relatedTarget as Node)) {
      return;
    }
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0 && chatContentRef.current) {
      chatContentRef.current.handleFilesDropped(files);
    }
  };

  useEffect(() => {
    if (userInfo?.picture) {
      getCachedImage(userInfo.picture)
        .then(setProfileImageUrl)
        .catch(() => setProfileImageUrl('profile-icon-design-free-vector.jpg'));
    }
  }, [userInfo?.picture]);

  return (
    <div 
      className={`App ${isSidebarOpen ? 'sidebar-open' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {isDragging && (
        <div className="drag-drop-overlay">
          <div className="drag-drop-content">
            <div className="drag-drop-icon">
              <svg 
                viewBox="0 0 24.00 24.00" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="drag-drop-text">
              Drop your files here
            </div>
          </div>
        </div>
      )}
      <Sidebar
        conversations={conversations}
        currentConversation={currentConversation}
        setCurrentConversation={(conv) => {
          handleConversationSelect(conv);
          setHasSubmittedQuery(true);
        }}
        createNewConversation={() => {
          createNewConversation();
          setHasSubmittedQuery(false);
        }}
        deleteConversation={deleteConversation}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        userInfo={userInfo}
        onOpenSettings={onOpenSettings}
        onOpenAnalytics={onOpenAnalytics}
        // onLogout={onLogout}
        projects={projects}
        createNewProject={createNewProject}
        deleteProject={deleteProject}
        editProject={handleEditProject} 
        isLoading={isLoading}
      />
      <div className="App-header">
        <ChatHeader toggleSidebar={toggleSidebar} />
         {shouldShowWelcomeInput ? (
          <div className="welcome-container">
            <WelcomeInput
              onSend={handleSend}
              isAssistantResponding={isAssistantResponding}
              pastedDocumentRefs={pastedDocumentRefs}
              handlePastedDocument={handlePastedDocument}
              removePastedDocument={removePastedDocument}
              isSidebarOpen={isSidebarOpen}
              projects={projects}
              onSelectProject={handleProjectSelect}
              ref={welcomeInputRef}
            />
          </div>
        ) : ( 
          <ChatContent
            ref={chatContentRef}
            currentConversation={currentConversation}
            isLoading={isLoading}
            error={error}
            theme={theme}
            chatContainerRef={chatContainerRef}
            handleSend={handleSend}
            setTheme={setTheme}
            isSidebarOpen={isSidebarOpen}
            fetchUpdatedConversation={fetchUpdatedConversation}
            handleConfirmation={handleConfirmation}
            isGenerating={isLoading}
            stopGeneration={stopGeneration}
            projects={projects}
            handleProjectSelect={handleProjectSelect}
          />
        )}
      </div>
    </div>
  );
}

export default Chat;