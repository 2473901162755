// src/components/Sidebar/Profile/ProfileSection.tsx

import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import DropdownMenu from './DropdownMenu';
import { X } from 'lucide-react';
import "./ProfileSection.css";

interface ProfileSectionProps {
  name: string;
  imageUrl: string;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  onLogout: () => Promise<void>;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ name, imageUrl, onOpenSettings, onOpenAnalytics,onLogout  }) => {
  const [open, setOpen] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="profile-section" ref={profileRef}>
      <button
        className="profile-trigger"
        onClick={() => setOpen(!open)}
      >
        <div className="profile-image-container">
          <img 
            src={imageUrl} 
            alt={name} 
            className="profile-image" 
            onError={(e) => {
              console.error("Error loading image:", e);
              e.currentTarget.src = "profile-icon-design-free-vector.jpg";
            }} 
          />
        </div>
        <h2 className="profile-name">{name}</h2>
      </button>
      
      <CSSTransition
        in={open}
        timeout={300}
        classNames="menu"
        unmountOnExit
      >
        <DropdownMenu 
          onOpenSettings={onOpenSettings}
          onOpenAnalytics={onOpenAnalytics}
          onLogout={onLogout}
          onClose={() => setOpen(false)}
        />
      </CSSTransition>
    </div>
  );
};

export default ProfileSection;