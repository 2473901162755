// src/components/Sidebar/Profile/ProfileSection.tsx

import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import DropdownMenu from './DropdownMenu';
import { X } from 'lucide-react';
import "./ProfileSection.css";

interface ProfileSectionProps {
  name: string;
  imageUrl: string;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ name, imageUrl, onOpenSettings, onOpenAnalytics }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="profile-section">
      <button
        className="profile-trigger"
        onClick={() => setOpen(!open)}
      >
        <div className="profile-image-container">
          <img 
            src={imageUrl} 
            alt={name} 
            className="profile-image" 
            onError={(e) => {
              console.error("Error loading image:", e);
              e.currentTarget.src = "profile-icon-design-free-vector.jpg";
            }} 
          />
        </div>
        <h2 className="profile-name">{name}</h2>
      </button>
      
      <CSSTransition
        in={open}
        timeout={300}
        classNames="menu"
        unmountOnExit
      >
        <DropdownMenu 
          onOpenSettings={onOpenSettings}
          onOpenAnalytics={onOpenAnalytics}
          onClose={() => setOpen(false)} // Add this line
        />
      </CSSTransition>
    </div>
  );
};

export default ProfileSection;