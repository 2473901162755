// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/MessageList/SourceList.css */

.source-list {
  margin-top: 10px;
  font-size: 14px;
}

.source-list p {
  margin-bottom: 5px;
  color: var(--text);
}

.source-list ul {
  list-style-type: none !important;
  padding-left: 10px;
}

.source-list li {
  margin-bottom: 5px !important;
}

.source-list a {
  color: var(--text) !important;
  font-family: 'Rethink-bold';
  text-decoration: none !important;
  transition: color 0.3s ease;
}

.source-list a:hover {
  color: var(--highlight) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageList/SourceList.css"],"names":[],"mappings":"AAAA,8CAA8C;;AAE9C;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,gCAAgC;EAChC,2BAA2B;AAC7B;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":["/* src/components/MessageList/SourceList.css */\n\n.source-list {\n  margin-top: 10px;\n  font-size: 14px;\n}\n\n.source-list p {\n  margin-bottom: 5px;\n  color: var(--text);\n}\n\n.source-list ul {\n  list-style-type: none !important;\n  padding-left: 10px;\n}\n\n.source-list li {\n  margin-bottom: 5px !important;\n}\n\n.source-list a {\n  color: var(--text) !important;\n  font-family: 'Rethink-bold';\n  text-decoration: none !important;\n  transition: color 0.3s ease;\n}\n\n.source-list a:hover {\n  color: var(--highlight) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
