import React, { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia, oneLight } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { DocumentRef } from '../../types';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

type CodeProps = {
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

interface LinkRendererProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string;
  children: React.ReactNode;
}

interface MessageTextProps {
  text: string | undefined;
  role: string;
  msgIndex: number;
  documentRefs: DocumentRef[] | undefined;
  codeBlockStates: { [key: string]: boolean };
  theme: 'dark' | 'light';  // Add theme prop
}

const LinkRenderer: React.FC<LinkRendererProps> = ({ href, children, ...props }) => {
  if (!href) return <>{children}</>;

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  );
};

// Inline code component
const InlineCode: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <code className="inline-code">{children}</code>
);
interface TableProps {
  children: React.ReactNode;
}

// Update HTMLTableAlignment type to match HTML table cell alignment options
type HTMLTableAlignment = 'left' | 'center' | 'right' | 'justify' | 'char' | undefined;
interface TableCellProps extends Omit<React.TdHTMLAttributes<HTMLTableCellElement>, 'align'> {
  isHeader?: boolean;
  align?: HTMLTableAlignment;
  children: React.ReactNode;
}

interface TableRowProps {
  children: React.ReactNode;
}

const TableComponent: React.FC<TableProps> = ({ children }) => (
  <div className="table-container">
    <table className="markdown-table">{children}</table>
  </div>
);

const TableRow: React.FC<TableRowProps> = ({ children }) => (
  <tr className="table-row">{children}</tr>
);

// Convert HTML alignment to CSS text-align value
const getTextAlign = (align: HTMLTableAlignment): React.CSSProperties['textAlign'] => {
  // Only return CSS-valid text-align values
  switch (align) {
    case 'left':
    case 'center':
    case 'right':
    case 'justify':
      return align;
    case 'char':
      return 'left'; // Default to left alignment for 'char'
    default:
      return undefined;
  }
};

const TableCell: React.FC<TableCellProps> = ({ 
  isHeader, 
  align, 
  children, 
  style, 
  className,
  ...props 
}) => {
  const Tag = isHeader ? 'th' : 'td';
  
  const combinedStyle: React.CSSProperties = {
    ...style,
    ...(align ? { textAlign: getTextAlign(align) } : {})
  };

  return (
    <Tag 
      {...props}
      align={align} // Keep HTML align attribute
      style={combinedStyle}
      className={`table-cell ${isHeader ? 'header-cell' : ''} ${className || ''}`}
    >
      {children}
    </Tag>
  );
};

export function MessageText({
  text,
  role,
  msgIndex,
  documentRefs,
  codeBlockStates,
  theme
}: MessageTextProps) {
  const [copiedBlocks, setCopiedBlocks] = useState<{ [key: string]: boolean }>({});
  // Add a counter ref to track code blocks within the message
  const codeBlockCounter = useRef(0);

  if (!text || text.trim() === "") return null;

  // Local copy function
  const handleCopy = async (text: string, codeKey: string) => {
    try {
      // First try the modern Clipboard API
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        // Fallback to the older execCommand method
        const textArea = document.createElement('textarea');
        textArea.value = text;
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Failed to copy using execCommand:', err);
          throw err;
        } finally {
          document.body.removeChild(textArea);
        }
      }
  
      // Update the copied state
      setCopiedBlocks(prev => ({ ...prev, [codeKey]: true }));
      
      // Reset copied state after 2 seconds
      setTimeout(() => {
        setCopiedBlocks(prev => ({ ...prev, [codeKey]: false }));
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
      // You might want to show a user-friendly error message here
    }
  };

  const cleanedText = documentRefs 
    ? documentRefs.reduce((acc, doc) => {
        return acc.replace(new RegExp(`{document:${doc.id}}`, 'g'), '');
      }, text).trim()
    : text;

  if (role !== 'assistant') {
    return <span className="text">{cleanedText}</span>;
  }

  const selectedTheme = theme === 'dark' ? okaidia : oneLight;

  const codeStyle = {
    ...selectedTheme,
    'pre[class*="language-"]': {
      ...selectedTheme['pre[class*="language-"]'],
      padding: '1em',
      margin: 0,
      background: '#ffffff'
    },
    'code[class*="language-"]': {
      ...selectedTheme['code[class*="language-"]'],
      paddingLeft: 0,
      background: '#ffffff'
    }
  };

  const renderMarkdown = (text: string, context: string) => {
    codeBlockCounter.current = 0;

    const key = `msg-${msgIndex}-text-${context}`;

    return (
      <ReactMarkdown
        key={key}
        components={{
          // Override paragraph handling for inline code
          p: ({ children }) => {
            // Check if paragraph contains only inline code
            if (React.Children.count(children) === 1 && React.isValidElement(children) && children.type === 'code' && children.props.inline) {
              return children;
            }
            return <p className="markdown-text">{children}</p>;
          },
          code: ({ inline, className, children, ...props }: CodeProps) => {
            if (inline) {
              return <InlineCode>{children}</InlineCode>;
            }

            const match = /language-(\w+)/.exec(className || '');
            const language = match ? match[1] : '';
            const blockIndex = codeBlockCounter.current++;
            const codeKey = `${key}-code-${language}-${blockIndex}`;
            
            if (!language) {
              console.log(children)
              return (
                <InlineCode>{children}</InlineCode>
              );
            }

            return (
              <div className="code-container complete">
                <div className="code-header">
                  <span className="code-lang">{language}</span>
                  <button
                    className={`copy-btn ${copiedBlocks[codeKey] ? 'copied' : ''}`}
                    onClick={() => handleCopy(String(children).replace(/\n$/, ''), codeKey)}
                  >
                    {copiedBlocks[codeKey] ? 'Copied!' : 'Copy code'}
                  </button>
                </div>
                <SyntaxHighlighter
                  language={language}
                  style={codeStyle as any}
                  showLineNumbers={false}
                  lineProps={{ style: { flexWrap: 'wrap' } }}
                  customStyle={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: '11px',
                    borderBottomRightRadius: '11px',
                    padding: '1em',
                    margin: 0,
                    background: (theme === 'dark' ? '#232323' : '#fafafa'),
                    overflow: 'auto',
                    scrollbarWidth: 'thin',
                  }}
                  PreTag="div"
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              </div>
            );
          },
          table: TableComponent as any,
          tr: TableRow as any,
          td: ({ children, style, className, ...props }) => (
            <TableCell {...props} style={style} className={className}>
              {children}
            </TableCell>
          ),
          th: ({ children, style, className, ...props }) => (
            <TableCell isHeader {...props} style={style} className={className}>
              {children}
            </TableCell>
          ),
          a: LinkRenderer as any,
          // Override default break handling
          br: () => ' '
        }}
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[rehypeKatex]}
      >
        {cleanedText}
      </ReactMarkdown>
    );
  };

  return renderMarkdown(cleanedText, 'main');
}
