// src/components/Sidebar/profile/SettingsOverlay/SettingsOverlay.tsx

import React, { useCallback } from 'react';
import './SettingsOverlay.css';
import { ThemeType } from '../../../types';

interface SettingsOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  currentTheme: ThemeType;
  onThemeChange: (theme: ThemeType) => void;
}

const themes: ThemeType[] = ['dark', 'light', 'orange', 'blue', 'racine'];

const SettingsOverlay: React.FC<SettingsOverlayProps> = ({
  isOpen,
  onClose,
  currentTheme,
  onThemeChange,
}) => {
  const handleOverlayClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    // Si le clic est sur l'overlay (fond) et non sur le contenu
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="settings-overlay" onClick={handleOverlayClick}>
      <div className="settings-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <span className="close-icon">&times;</span>
        </button>
        <div className="theme-selector">
          <h3>Pick a theme:</h3>
          <div className="themes-buttons">
            {themes.map(theme => (
              <button
                key={theme}
                className={`theme-button ${currentTheme === theme ? 'active' : ''}`}
                onClick={() => onThemeChange(theme)}
              >
                {theme.charAt(0).toUpperCase() + theme.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsOverlay;