// src/components/InputArea/InputArea.tsx

import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getFileIcon } from '../utils';
import './InputArea.css';
import { DocumentRef, FileMetadata } from '../types';
import DocumentEditPopup from './DocumentEditPopup';
import ImageViewPopup from './ImageViewPopup';

interface InputAreaProps {
  onSend: (input: string, tempFiles: FileMetadata[]) => Promise<void>;  // Simplifiez la signature
  isAssistantResponding: boolean;
  pastedDocumentRefs: DocumentRef[];
  handlePastedDocument: (document: DocumentRef) => void;
  removePastedDocument: (id: string) => void;
  updatePastedDocument: (updatedDocument: DocumentRef) => void;
  isSidebarOpen: boolean;
  isGenerating?: boolean;
  stopGeneration: () => void; // Ajoutez cette ligne
}

const InputArea = React.forwardRef<
  { handleFileUpload: (file: File) => void },
  InputAreaProps
>(({
  isAssistantResponding,
  onSend,
  pastedDocumentRefs,
  handlePastedDocument,
  removePastedDocument,
  stopGeneration,
  updatePastedDocument,
  isSidebarOpen,
  isGenerating = false,
}, ref) => {
  const [input, setInput] = useState<string>('');
  const [tempFiles, setTempFiles] = useState<FileMetadata[]>([]);
  const [fileWarning, setFileWarning] = useState<string>('');
  const [editingDocument, setEditingDocument] = useState<DocumentRef | null>(null);
  const [viewingImage, setViewingImage] = useState<DocumentRef | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const MAX_FILES = 5;
  const MAX_PREVIEW_LENGTH = 1000;
  React.useImperativeHandle(ref, () => ({
    handleFileUpload: (file: File) => {
      if (tempFiles.length >= MAX_FILES) {
        setFileWarning(`You can only add up to ${MAX_FILES} files.`);
        return;
      }
      handleFileUpload(file);
    }
  }));

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = '20px';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 180)}px`;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = () => {
    if (input.trim() === '') return;
    onSend(input, tempFiles);
    setInput('');
    setTempFiles([]);
    // Empty the pasted documents
    pastedDocumentRefs.forEach(doc => removePastedDocument(doc.id));
    if (textareaRef.current) {
      textareaRef.current.style.height = '40px';
    }
  };

  const handleFileUpload = (file: File) => {
    if (tempFiles.length >= MAX_FILES) {
      setFileWarning(`You can only add up to ${MAX_FILES} files.`);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = e.target?.result as string;
      let newDocument: DocumentRef;

      if (file.type.startsWith('image/')) {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'image',
          mime_type: file.type,
        };
      } else if (file.type === 'text/plain') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'text',
          mime_type: file.type,
        };
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'xlsx',
          mime_type: file.type,
        };
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'pptx',
          mime_type: file.type,
        };
      } else if (file.type === 'application/pdf') {
        newDocument = {
          id: uuidv4(),
          content: url.split(',')[1],
          name: file.name,
          type: 'pdf',
          mime_type: file.type,
        };
      } else {
        // For any other file type, we'll add it to tempFiles
        setTempFiles(prev => [...prev, { name: file.name, type: file.type, url }]);
        return;
      }

      handlePastedDocument(newDocument);
    };
    reader.readAsDataURL(file);
  };

  // Function to construct full data URL from base64 and MIME type
  const getImageUrl = (base64: string, mimeType: string) => {
    return `data:${mimeType};base64,${base64}`;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData('text');
    const pastedItems = event.clipboardData.items;

    // Check for pasted text
    if (pastedText.length > MAX_PREVIEW_LENGTH) {
      event.preventDefault();
      const newDocument: DocumentRef = {
        id: uuidv4(),
        content: pastedText,
        name: 'Pasted text',
        type: 'text'
      };
      handlePastedDocument(newDocument);
      return;
    }

    const createDocumentFromFile = (blob: File, type: 'image' | 'xlsx' | 'pptx' | 'pdf') => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const url = e.target?.result as string;
        const newDocument: DocumentRef = {
          id: uuidv4(),
          content: url.split(',')[1], // Remove the data:xxx;base64, part
          name: `Pasted ${type}`,
          type: type,
          mime_type: blob.type,
        };
        handlePastedDocument(newDocument);
      };
      reader.readAsDataURL(blob);
    };

    // Check for pasted items
    for (let i = 0; i < pastedItems.length; i++) {
      const item = pastedItems[i];
      
      if (item.kind === 'file') {
        const blob = item.getAsFile();
        if (blob) {
          event.preventDefault();
          
          if (item.type === 'text/plain') {
            // Handle .txt file
            const reader = new FileReader();
            reader.onload = (e) => {
              const content = e.target?.result as string;
              const newDocument: DocumentRef = {
                id: uuidv4(),
                content: content,
                name: 'Pasted .txt file',
                type: 'text',
              };
              handlePastedDocument(newDocument);
            };
            reader.readAsText(blob);
          } else if (item.type.indexOf('image') !== -1) {
            createDocumentFromFile(blob, 'image');
          } else if (item.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            createDocumentFromFile(blob, 'xlsx');
          } else if (item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            createDocumentFromFile(blob, 'pptx');
          } else if (item.type === 'application/pdf') {
            createDocumentFromFile(blob, 'pdf');
          }
          break;
        }
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setTempFiles(prev => prev.filter((_, i) => i !== index));
    setFileWarning('');
  };

  const handleDocumentClick = (doc: DocumentRef) => {
    if (doc.type === 'image') {
      setViewingImage(doc);
    } else {
      setEditingDocument(doc);
    }
  };

  const handleDocumentUpdate = (updatedDocument: DocumentRef) => {
    updatePastedDocument(updatedDocument);
    setEditingDocument(null);
  };

  const triggerFileUpload = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [input]);

  useEffect(() => {
    if (fileWarning) {
      const timer = setTimeout(() => {
        setFileWarning('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [fileWarning]);

  useEffect(() => {
    if (!isAssistantResponding  && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isAssistantResponding ]);

  return (
    <div className="input-container">
      <div className={`pasted-documents-preview ${pastedDocumentRefs.length === 0 ? 'empty' : ''}`}>
        {pastedDocumentRefs.map((doc) => (
          <div key={doc.id} className={`pasted-document ${doc.type === 'image' ? 'image-document' : 'text-document'}`}>
            {doc.type === 'image' ? (
              <img 
                src={getImageUrl(doc.content, doc.mime_type || "")}
                alt={doc.name} 
                className="pasted-image-preview" 
                onClick={() => handleDocumentClick(doc)}
              />
            ) : (
              <div className="text-preview" onClick={() => handleDocumentClick(doc)}>
                <p>{doc.content.slice(0, 200)}{doc.content.length > 200 ? '...' : ''}</p>
              </div>
            )}
            <div className="document-info">
              <span className="document-name">{doc.name}</span>
              <button className="remove-document-btn" onClick={() => removePastedDocument(doc.id)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
        accept="*/*"
        style={{ display: 'none' }}
        className="file-input"
      />
      {fileWarning && <div className="file-warning">{fileWarning}</div>}
      {tempFiles.length > 0 && (
        <div className="file-preview-container">
          {tempFiles.map((file, index) => (
            <div key={index} className="temp-file-container">
              <i className={`fas ${getFileIcon(file.type)}`}></i>
              <span>{file.name}</span>
              <button className="remove-file-btn" onClick={() => handleRemoveFile(index)}>
                <i className="fas fa-times"></i>
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="input-content">
        <button 
          className="upload-btn" 
          onClick={triggerFileUpload}
        >
          <i className="fas fa-paperclip"></i>
        </button>
        <textarea 
          className="search-area"
          ref={textareaRef}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder="Type your message here..."
          rows={1}
          // J'ai retiré le disabled={isAssistantResponding} ici
        />
        {isGenerating ? (
          <button className="stop-btn" onClick={stopGeneration} aria-label="Stop generation">
            <svg className="stop-icon" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle 
                className="stop-circle"
                cx="18" 
                cy="18" 
                r="14" 
                stroke="currentColor" 
                strokeWidth="2"
                strokeDasharray="20,100"
                transform-origin="center"
              />
              <rect x="14" y="14" width="8" height="8" rx="1" fill="currentColor"/>
            </svg>
          </button>
        ) : (
          <button 
            className="send-btn" 
            onClick={handleSend}
            // J'ai aussi retiré le disabled={isAssistantResponding} ici
          >
            <svg width="30" height="30" viewBox="-5 -5 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="send-icon">
              <path d="M10.6012 15.3935L24.6111 1.38887M10.9995 16.0233L14.1762 22.3769C14.9439 23.9122 15.3277 24.68 15.8113 24.8858C16.231 25.0645 16.7107 25.032 17.1026 24.7987C17.5542 24.5299 17.8315 23.7175 18.3863 22.093L24.3885 4.51488C24.8719 3.09941 25.1135 2.39166 24.9481 1.92347C24.8042 1.51618 24.4838 1.19579 24.0766 1.05189C23.6083 0.886479 22.9006 1.12815 21.4851 1.61148L3.90692 7.61379C2.28241 8.1685 1.47014 8.44586 1.20126 8.89743C0.967906 9.2893 0.935572 9.76901 1.11422 10.1886C1.32008 10.6722 2.08778 11.0561 3.62317 11.8237L9.97669 15.0006C10.2297 15.1271 10.3562 15.1902 10.4657 15.2747C10.5631 15.3498 10.6503 15.437 10.7252 15.5342C10.8098 15.6438 10.873 15.7703 10.9995 16.0233Z" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        )}
      </div>
      {viewingImage && (
        <ImageViewPopup
          documentRef={viewingImage}
          onClose={() => setViewingImage(null)}
        />
      )}
      {editingDocument && (
        <DocumentEditPopup
          document={editingDocument}
          onSave={handleDocumentUpdate}
          onClose={() => setEditingDocument(null)}
          isSidebarOpen={isSidebarOpen}
        />
      )}
     </div>
  );
}); // Fermeture du forwardRef avec point-virgule

export default InputArea;