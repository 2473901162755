// src/components/MessageList/hooks/useCodeBlockStates.ts
import { useEffect, useState } from 'react';
import { Message } from '../../types';

export function useCodeBlockStates(messages: Message[]) {
  const [codeBlockStates, setCodeBlockStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const newCodeBlockStates: { [key: string]: boolean } = {};
    messages.forEach((msg, msgIndex) => {
      if (msg.text) {
        const codeBlocks = msg.text.match(/```[\s\S]*?```|```[\s\S]*?$/g) || [];
        codeBlocks.forEach((block, blockIndex) => {
          const key = `${msgIndex}-${blockIndex}`;
          newCodeBlockStates[key] = block.endsWith('```');
        });
      }
    });
    setCodeBlockStates(newCodeBlockStates);
  }, [messages]);

  return { codeBlockStates };
}