// src/components/InputArea/DocumentEditPopup.tsx

import React, { useState, useEffect } from 'react';
import { DocumentRef } from '../types';
import './DocumentEditPopup.css';

interface DocumentEditPopupProps {
  document: DocumentRef;
  onSave: (updatedDocument: DocumentRef) => void;
  onClose: () => void;
  isSidebarOpen: boolean;
}

const DocumentEditPopup: React.FC<DocumentEditPopupProps> = ({ document, onSave, onClose, isSidebarOpen }) => {
  const [editedContent, setEditedContent] = useState(document.content);

  useEffect(() => {
    setEditedContent(document.content);
  }, [document]);

  const handleSave = () => {
    onSave({ ...document, content: editedContent });
    console.log('Saving document:', editedContent);
    onClose();
  };

  return (
    <div className={`document-edit-popup-overlay ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <div className="document-edit-popup">
        <h2 className="document-edit-title">Edit Document</h2>
        <textarea
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          className="document-edit-textarea"
        />
        <div className="document-edit-buttons">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default DocumentEditPopup;
