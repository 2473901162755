// src/components/MessageList/ImageParticleAnimation.tsx

import React, { useState, useEffect } from 'react';
import { useSpring, animated, SpringValue } from 'react-spring';

interface Particle {
  id: number;
  x: number;
  y: number;
  size: number;
  duration: number;
}

interface ImageParticleAnimationProps {
  src: string;
  alt: string;
  justUploaded: boolean;
}

const ImageParticleAnimation: React.FC<ImageParticleAnimationProps> = ({ src, alt, justUploaded }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [particles, setParticles] = useState<Particle[]>([]);

  const imageAnimation = useSpring({
    from: justUploaded ? { opacity: 0, transform: 'scale(0.8)' } : { opacity: 1, transform: 'scale(1)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 300, friction: 10 },
  });

  useEffect(() => {
    if (justUploaded) {
      setIsVisible(true);
      const particleCount = 50;
      const newParticles: Particle[] = Array.from({ length: particleCount }, (_, i) => ({
        id: i,
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 15 + 2,
        duration: Math.random() * 1000 + 1500,
      }));
      setParticles(newParticles);

      const timer = setTimeout(() => {
        setParticles([]);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [justUploaded]);

  if (!justUploaded) {
    return (
      <img
        src={src}
        alt={alt}
        style={{
          maxHeight: '50vh',
          borderRadius: '15px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      />
    );
  }

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <animated.img
        src={src}
        alt={alt}
        style={{
          ...imageAnimation,
          maxHeight: '50vh',
          borderRadius: '15px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      />
      {particles.map((particle) => (
        <ParticleAnimation key={particle.id} particle={particle} />
      ))}
    </div>
  );
};

const ParticleAnimation: React.FC<{ particle: Particle }> = ({ particle }) => {
  const style = useSpring({
    from: { opacity: 1, scale: 1 },
    to: { opacity: 0, scale: 0 },
    config: { duration: particle.duration },
  });

  return (
    <animated.div
      style={{
        position: 'absolute',
        left: `${particle.x}%`,
        top: `${particle.y}%`,
        width: `${particle.size}px`,
        height: `${particle.size}px`,
        backgroundColor: 'var(--highlight)',
        borderRadius: '50%',
        opacity: style.opacity,
        transform: style.scale.to((s) => `scale(${s})`),
      }}
    />
  );
};

export default ImageParticleAnimation;