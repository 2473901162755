// src/apiWrapper.ts
import { TokenResponse } from './components/types';

const API_URL = process.env.REACT_APP_API_URL;

// Define interface for cached requests
interface CachedRequest<T> {
  promise: Promise<T>;
  timestamp: number;
}

// Type-safe request cache
let activeRequests: Record<string, CachedRequest<any>> = {};

export const apiCall = async <T>(url: string, options: RequestInit = {}): Promise<T> => {
  const cacheKey = `${options.method || 'GET'}-${url}`;
  const now = Date.now();

  // Check for existing valid request
  if (activeRequests[cacheKey] && now - activeRequests[cacheKey].timestamp < 1000) {
    return activeRequests[cacheKey].promise;
  }

  const token = localStorage.getItem('token');
  
  const makeRequest = async (token: string | null): Promise<T> => {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...options.headers as Record<string, string>,
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      const newTokenResponse = await refreshToken();
      if (newTokenResponse && newTokenResponse.access_token) {
        return makeRequest(newTokenResponse.access_token);
      }
      window.location.href = '/login';
      throw new Error('Authentication failed');
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  // Create and cache the request
  const request = makeRequest(token);
  activeRequests[cacheKey] = {
    promise: request,
    timestamp: now
  };

  try {
    return await request;
  } finally {
    // Clean up the cache after request completes
    setTimeout(() => {
      if (activeRequests[cacheKey]?.timestamp === now) {
        delete activeRequests[cacheKey];
      }
    }, 1000);
  }
};

export const streamingApiCall = async (url: string, options: RequestInit = {}): Promise<Response> => {
  const cacheKey = `streaming-${options.method || 'GET'}-${url}`;
  const now = Date.now();

  if (activeRequests[cacheKey] && now - activeRequests[cacheKey].timestamp < 1000) {
    return activeRequests[cacheKey].promise;
  }

  const token = localStorage.getItem('token');
  
  const makeRequest = async (token: string | null): Promise<Response> => {
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      ...options.headers as Record<string, string>,
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      const newTokenResponse = await refreshToken();
      if (newTokenResponse && newTokenResponse.access_token) {
        return makeRequest(newTokenResponse.access_token);
      }
      window.location.href = '/login';
      throw new Error('Authentication failed');
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  };

  if (!token && !url.endsWith('/auth/google')) {
    throw new Error('No token available');
  }

  const request = makeRequest(token);
  activeRequests[cacheKey] = {
    promise: request,
    timestamp: now
  };

  try {
    return await request;
  } finally {
    setTimeout(() => {
      if (activeRequests[cacheKey]?.timestamp === now) {
        delete activeRequests[cacheKey];
      }
    }, 1000);
  }
};

// Type-safe refresh token cache
interface RefreshTokenCache {
  promise: Promise<TokenResponse | null>;
  timestamp: number;
}

let refreshTokenCache: RefreshTokenCache | null = null;

export const refreshToken = async (): Promise<TokenResponse | null> => {
  const now = Date.now();

  if (refreshTokenCache && now - refreshTokenCache.timestamp < 1000) {
    return refreshTokenCache.promise;
  }

  const refreshTokenValue = localStorage.getItem('refreshToken');
  if (!refreshTokenValue) {
    return null;
  }

  const request = (async () => {
    try {
      const response = await fetch(`${API_URL}/refresh_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshTokenValue }),
      });

      if (response.ok) {
        const data: TokenResponse = await response.json();
        if (data.valid && data.access_token) {
          localStorage.setItem('token', data.access_token);
          if (data.refresh_token) {
            localStorage.setItem('refreshToken', data.refresh_token);
          }
          return data;
        }
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
    }
    return null;
  })();

  refreshTokenCache = {
    promise: request,
    timestamp: now
  };

  try {
    return await request;
  } finally {
    setTimeout(() => {
      if (refreshTokenCache?.timestamp === now) {
        refreshTokenCache = null;
      }
    }, 1000);
  }
};

export const analyticsApiCall = async <T>(endpoint: string, options: RequestInit = {}): Promise<T> => {
  const url = `${API_URL}${endpoint}`;
  return apiCall<T>(url, options);
};

export const resetApiCache = () => {
  activeRequests = {};
  refreshTokenCache = null;
};