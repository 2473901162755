// src/components/MessageList/SourceList.tsx

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './SourceList.css';

interface Source {
  title: string;
  url: string;
}

interface SourceListProps {
  sources: Source[];
}

const SourceItem: React.FC<{ source: Source; delay: number }> = ({ source, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  const animation = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(10px)',
    config: { tension: 100, friction: 10 },
  });

  return (
    <animated.li style={animation}>
      <a
        href={source.url}
        target="_blank"
        rel="noopener noreferrer"
        title={source.url}
      >
        <strong>↳ {source.title}</strong>
      </a>
    </animated.li>
  );
};

const SourceList: React.FC<SourceListProps> = ({ sources }) => {
  return (
    <div className="source-list">
      <p>Sources:</p>
      <ul>
        {sources.map((source, index) => (
          <SourceItem key={index} source={source} delay={index * 500} />
        ))}
      </ul>
    </div>
  );
};

export default SourceList;
