// useScrollBehavior.ts
import { useCallback, useEffect, useRef } from 'react';

export class ScrollManager {
  private container: HTMLDivElement | null = null;
  private lastScrollTop: number = 0;
  private lastContentHeight: number = 0;
  private shouldAutoScroll: boolean = true;
  private userHasScrolledUp: boolean = false;
  private touchInProgress: boolean = false;
  private scrollTimeoutId: number | null = null;
  private bottomPadding: number = 100;
  
  setContainer(element: HTMLDivElement) {
    this.container = element;
    this.lastContentHeight = element.scrollHeight;
  }

  // Changed to public
  public isNearBottom(): boolean {
    if (!this.container) return false;
    const { scrollTop, scrollHeight, clientHeight } = this.container;
    return scrollHeight - scrollTop - clientHeight < this.bottomPadding;
  }

  // Add public getter
  public getShouldAutoScroll(): boolean {
    return this.shouldAutoScroll;
  }

  private checkContentHeightChange() {
    if (!this.container) return false;
    const currentHeight = this.container.scrollHeight;
    const hasChanged = currentHeight !== this.lastContentHeight;
    this.lastContentHeight = currentHeight;
    return hasChanged;
  }

  public handleScroll = () => {
    if (!this.container) return;
    
    const { scrollTop } = this.container;
    const scrollingUp = scrollTop < this.lastScrollTop;
    
    if (scrollingUp) {
      this.shouldAutoScroll = false;
      this.userHasScrolledUp = true;
    } else if (this.isNearBottom()) {
      this.shouldAutoScroll = true;
      this.userHasScrolledUp = false;
    }
    
    this.lastScrollTop = scrollTop;
  }

  public handleTouchStart = () => {
    this.touchInProgress = true;
    this.shouldAutoScroll = false;
  }

  public handleTouchEnd = () => {
    this.touchInProgress = false;
    if (this.isNearBottom()) {
      this.shouldAutoScroll = true;
      this.userHasScrolledUp = false;
    }
  }

  public scrollToBottom(force: boolean = false) {
    if (!this.container || (!force && !this.shouldAutoScroll)) return;
    if (this.touchInProgress) return;
    
    // Only scroll if content height has changed
    if (!force && !this.checkContentHeightChange()) return;

    // Use requestAnimationFrame for smooth scrolling
    requestAnimationFrame(() => {
      if (!this.container) return;
      const scrollTarget = this.container.scrollHeight - this.container.clientHeight;
      
      // Check if the start of the response is at the top
      const responseElement = this.container.querySelector('.assistant:last-child');
      if (responseElement) {
        const responseTop = responseElement.getBoundingClientRect().top;
        const containerTop = this.container.getBoundingClientRect().top;
        if (responseTop <= containerTop) {
          this.shouldAutoScroll = false;
          return;
        }
      }

      this.container.scrollTo({
        top: scrollTarget,
        behavior: force ? 'auto' : 'smooth'
      });
    });
  }
}

export function useScrollBehavior(
  chatContainerRef: React.RefObject<HTMLDivElement>,
  lastMessageRef: React.RefObject<HTMLDivElement>
) {
  const scrollManagerRef = useRef<ScrollManager>(new ScrollManager());

  const scrollToBottom = useCallback((force: boolean = false) => {
    scrollManagerRef.current.scrollToBottom(force);
  }, []);

  const isNearBottom = useCallback(() => {
    return scrollManagerRef.current.isNearBottom();
  }, []);

  const shouldAutoScroll = useCallback(() => {
    return scrollManagerRef.current.getShouldAutoScroll();
  }, []);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    const scrollManager = scrollManagerRef.current;
    scrollManager.setContainer(container);

    const handleScroll = scrollManager.handleScroll;
    const handleTouchStart = scrollManager.handleTouchStart;
    const handleTouchEnd = scrollManager.handleTouchEnd;

    // Use passive listeners for better performance
    container.addEventListener('scroll', handleScroll, { passive: true });
    container.addEventListener('touchstart', handleTouchStart, { passive: true });
    container.addEventListener('touchend', handleTouchEnd, { passive: true });

    return () => {
      container.removeEventListener('scroll', handleScroll);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, [chatContainerRef]);

  return {
    scrollToBottom,
    isNearBottom,
    shouldAutoScroll
  };
}