// src/components/Login.tsx

import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { apiCall } from '../../apiWrapper';
import { TokenResponse } from '../types';
import './Login.css';
import Typewriter from './Typewriter'; // Import the Typewriter component

const API_URL = process.env.REACT_APP_API_URL;

interface LoginProps {
  setIsValidToken: React.Dispatch<React.SetStateAction<boolean>>;
  fetchUserInfo: () => Promise<void>;
}

const Login = ({ setIsValidToken, fetchUserInfo }: LoginProps) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);

      try {
        const data = await apiCall<TokenResponse>(`${API_URL}/auth/google`, {
          method: 'POST',
          body: JSON.stringify({ code: tokenResponse.code }),
        });

        console.log('Received token data:', data);

        if (data.access_token && data.expires_in) {
          localStorage.setItem('token', data.access_token);
          localStorage.setItem(
            'tokenExpiry',
            (Date.now() + data.expires_in * 1000).toString()
          );

          if (data.refresh_token) {
            localStorage.setItem('refreshToken', data.refresh_token);
          }

          setIsValidToken(true);
          await fetchUserInfo();
        } else {
          console.error('Invalid token data received');
        }
      } catch (error) {
        console.error('Failed to exchange code for tokens', error);
      }
    },
    flow: 'auth-code',
  });

  // Define the texts to cycle through
  const typewriterTexts = [
    'racine.ai can write your emails',
    'racine.ai can use your documents',
    'racine.ai can automate your work',
  ];

  return (
    <div className="login-page">
      <div className="login-container">
        {/* Use the Typewriter component */}
        <Typewriter texts={typewriterTexts} />
        <button onClick={() => login()} className="login-btn">
          Sign in with Google
        </button>
      </div>
      <footer className="login-footer">
        By signing in, you agree to our{' '}
        <a href="#">Terms of Service</a> and{' '}
        <a href="#">Privacy Policy</a>
      </footer>
    </div>
  );
};

export default Login;
