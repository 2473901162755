// src/imageCache.ts
interface ImageCache {
  url: string;
  blob: Blob;
  timestamp: number;
}

const IMAGE_CACHE_DURATION = 1000 * 60 * 60; // 1 hour
const imageCache: Map<string, ImageCache> = new Map();

export const getCachedImage = async (url: string): Promise<string> => {
  const now = Date.now();
  const cached = imageCache.get(url);

  // Return cached blob URL if it exists and isn't expired
  if (cached && (now - cached.timestamp < IMAGE_CACHE_DURATION)) {
    return URL.createObjectURL(cached.blob);
  }

  try {
    const response = await fetch(url, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const blob = await response.blob();
    
    // Cache the new image
    imageCache.set(url, {
      url,
      blob,
      timestamp: now
    });

    // Clean up old cache entries
    // Using Array.from() to avoid the iteration error
    Array.from(imageCache.entries()).forEach(([cachedUrl, cachedData]) => {
      if (now - cachedData.timestamp > IMAGE_CACHE_DURATION) {
        imageCache.delete(cachedUrl);
      }
    });

    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching image:', error);
    // Return a default avatar image URL
    return '/default-avatar.png';
  }
};

// Helper function to get profile image URL
export const getProfileImageUrl = (originalUrl: string): string => {
  if (!originalUrl) return '/default-avatar.png';
  return originalUrl;
};