import React, { useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';
import { DocumentRef } from '../types';
import './ImageViewPopup.css';

interface ImageViewPopupProps {
  documentRef: DocumentRef;
  onClose: () => void;
}

const ImageViewPopup: React.FC<ImageViewPopupProps> = ({ documentRef, onClose }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const getImageUrl = (base64: string, mimeType: string) => {
    return `data:${mimeType};base64,${base64}`;
  };

  const handleBackgroundClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === popupRef.current) {
      onClose();
    }
  }, [onClose]);

  // Use createPortal to render the popup directly under the body
  return createPortal(
    <div className="image-view-popup-overlay" ref={popupRef} onClick={handleBackgroundClick}>
      <div className="image-view-popup">
        <img 
          src={getImageUrl(documentRef.content, documentRef.mime_type || "png")} 
          alt={documentRef.name} 
          className="full-size-image"
        />
        <button className="close-button-image" onClick={onClose}>
          <X size={18} />
        </button>
      </div>
    </div>,
    document.body // Render directly to body
  );
};

export default ImageViewPopup;