// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TableStyles.css */

/* Special class added to tables when copying - cette partie est importante pour la fonctionnalité */
.table-for-copy {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
}

.table-for-copy th,
.table-for-copy td {
  border: 1px solid #ccc;
  padding: 5px;
}

/* Feedback visuel très discret uniquement lors du focus */
.message-content table:focus,
.message-content table:active {
  outline: 1px solid var(--highlight);
}`, "",{"version":3,"sources":["webpack://./src/components/MessageList/components/TableStyles.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC,oGAAoG;AACpG;EACE,yBAAyB;EACzB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;AACd;;AAEA,0DAA0D;AAC1D;;EAEE,mCAAmC;AACrC","sourcesContent":["/* src/components/TableStyles.css */\n\n/* Special class added to tables when copying - cette partie est importante pour la fonctionnalité */\n.table-for-copy {\n  border-collapse: collapse;\n  border-spacing: 0;\n  border: 1px solid #ccc;\n}\n\n.table-for-copy th,\n.table-for-copy td {\n  border: 1px solid #ccc;\n  padding: 5px;\n}\n\n/* Feedback visuel très discret uniquement lors du focus */\n.message-content table:focus,\n.message-content table:active {\n  outline: 1px solid var(--highlight);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
