// src/components/Sidebar/Sidebar.tsx

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faTrash, faSearch, faFolder } from '@fortawesome/free-solid-svg-icons';
import { Conversation, Folder, UserInfo } from '../types';
import ProfileSection from './Profile/ProfileSection';
import logoImage from '../../racine-logo.png';

import "./Sidebar.css";

interface SidebarProps {
  conversations: Conversation[];
  currentConversation: Conversation | null;
  setCurrentConversation: (conversation: Conversation) => void;
  createNewConversation: () => void;
  deleteConversation: (id: string) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  userInfo: UserInfo | null;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  onLogout: () => Promise<void>; 
}

const Sidebar: React.FC<SidebarProps> = ({
  conversations,
  currentConversation,
  setCurrentConversation,
  createNewConversation,
  deleteConversation,
  isSidebarOpen,
  userInfo,
  onOpenSettings,
  onOpenAnalytics,
  onLogout,
}: SidebarProps) => {
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [folders, setFolders] = useState<Folder[]>([]);

  useEffect(() => {
    // Fetch folders from your API here
    // For now, we'll use an empty array
    setFolders([]);
  }, []);

  const handleConversationClick = (conv: Conversation) => {
    setCurrentConversation(conv);
  };

  const handleMenuClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    setMenuOpen(menuOpen === id ? null : id);
  };

  const handleDeleteClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    deleteConversation(id);
    setMenuOpen(null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!event.target || !(event.target as HTMLElement).closest('.menu-container, .dropdown-menu')) {
      setMenuOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredConversations = conversations
  .filter(conv => conv.title.toLowerCase().includes(searchTerm.toLowerCase()))
  // Filtrer les conversations avec des IDs valides
  .filter(conv => conv.id && conv.id !== 'temp-id');

  

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div 
              className="sidebar-logo"
              style={{ 
                WebkitMaskImage: `url(${logoImage})`,
                maskImage: `url(${logoImage})`,
                WebkitMaskSize: 'contain',
                maskSize: 'contain',
                WebkitMaskRepeat: 'no-repeat',
                maskRepeat: 'no-repeat',
                backgroundColor: `var(--logo-color)`,
                width: '50%',
                height: '60px',
              }}
            />
            <div className="button-container">
              <button className="new-conversation-btn" onClick={createNewConversation}>
                <FontAwesomeIcon icon={faEdit} className="icon" />
              </button>
            </div>
          </div>

          <div className="search-container">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text" 
              placeholder="Search..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>

          {/* <div className="folders">
            <h2>Folders</h2>
            <div className="folder-group">
            </div>
          </div> */}

          <div className="conversations-wrapper">
            <h2>Conversations</h2>
            <div className="conversations-scroll-area">
              {filteredConversations.map((conv) => (
                <div
                  key={conv.id}
                  className={`conversation-item ${currentConversation?.id === conv.id ? 'active' : ''}`}
                  onClick={() => handleConversationClick(conv)}
                >
                  <span className="conversation-preview">{conv.title}</span>
                  <div className="menu-container">
                    <button className="drop-btn" onClick={(e) => handleMenuClick(e, conv.id)}>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {menuOpen === conv.id && (
                      <div className="dropdown-menu">
                        <button className="delete-btn" onClick={(e) => handleDeleteClick(e, conv.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {userInfo && (
        <div className="sidebar-footer">
          <ProfileSection
          name={userInfo?.name || ''}
          imageUrl={userInfo?.picture || ''}
          onOpenSettings={onOpenSettings}
          onOpenAnalytics={onOpenAnalytics}
          onLogout={onLogout} 
        />
        </div>
      )}
      </div>
    </div>
  );
}

export default Sidebar;