// src/components/Sidebar/Sidebar.tsx

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisV, faTrash, faSearch, faFolder, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Conversation, Folder, UserInfo } from '../types';
import ProfileSection from './Profile/ProfileSection';
import { Project,ProjectFile } from '../types';
import ProjectSection from './Project/ProjectSection';
import logoImage from '../../racine-logo.png';

import "./Sidebar.css";

interface NewProjectData {
  name: string;
  context: string;
  files: ProjectFile[];
}

interface ProjectSectionProps {
  projects: Project[];
  onCreateProject: (projectData: NewProjectData) => void;
  onDeleteProject: (id: string) => void;
  onEditProject: (project: Project) => void;
}

interface SidebarProps {
  conversations: Conversation[];
  currentConversation: Conversation | null;
  setCurrentConversation: (conversation: Conversation) => void;
  createNewConversation: () => void;
  deleteConversation: (id: string) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  userInfo: UserInfo | null;
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  // onLogout: () => Promise<void>; 
  projects: Project[];
  createNewProject: (projectData: NewProjectData) => void;
  deleteProject: (id: string) => void;
  editProject: (project: Project) => void;
  isLoading: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  conversations,
  currentConversation,
  setCurrentConversation,
  createNewConversation,
  deleteConversation,
  isSidebarOpen,
  userInfo,
  onOpenSettings,
  onOpenAnalytics,
  projects,
  createNewProject,
  deleteProject,
  editProject,
  isLoading,
}: SidebarProps) => {
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [folders, setFolders] = useState<Folder[]>([]);
  const [isProjectsVisible, setIsProjectsVisible] = useState(true);
  const [isConversationsVisible, setIsConversationsVisible] = useState(true);

  useEffect(() => {
    // Fetch folders from your API here
    // For now, we'll use an empty array
    setFolders([]);
  }, []);

  const handleConversationClick = (conv: Conversation) => {
    setCurrentConversation(conv);
  };

  const handleMenuClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    setMenuOpen(menuOpen === id ? null : id);
  };

  const handleDeleteClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    deleteConversation(id);
    setMenuOpen(null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!event.target || !(event.target as HTMLElement).closest('.menu-container, .dropdown-menu')) {
      setMenuOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredConversations = conversations.filter(conv => 
    conv.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div 
              className="sidebar-logo"
              style={{ 
                WebkitMaskImage: `url(${logoImage})`,
                maskImage: `url(${logoImage})`,
                WebkitMaskSize: 'contain',
                maskSize: 'contain',
                WebkitMaskRepeat: 'no-repeat',
                maskRepeat: 'no-repeat',
                backgroundColor: `var(--highlight)`,
                width: '60%',
                height: '60px',
              }}
            />
          </div>

          <div className="search-container">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text" 
              placeholder="Search..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
{/* 
          <div className="projects-wrapper">
            <div className="section-header">
              <div className="section-title-container">
                <h2>Projects</h2>
              </div>
              <button 
                className={`toggle-section-btn ${!isProjectsVisible ? 'collapsed' : ''}`}
                onClick={() => setIsProjectsVisible(!isProjectsVisible)}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </div>
            <div className={`section-content ${!isProjectsVisible ? 'collapsed' : ''}`}>
              <ProjectSection 
                projects={projects}
                onCreateProject={createNewProject}
                onDeleteProject={deleteProject}
                onEditProject={editProject}
              />
            </div>
          </div> */}

          <div className="conversations-wrapper">
            <h2 className="conversation-title">Conversations</h2>
            <button 
              className={`add-conversation-btn ${isLoading ? 'disabled' : ''}`}
              onClick={createNewConversation}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>New Conversation</span>
            </button>
            <div className="conversations-scroll-area">
              {filteredConversations.map((conv) => (
                <div
                  key={conv.id}
                  className={`conversation-item ${currentConversation?.id === conv.id ? 'active' : ''}`}
                  onClick={() => handleConversationClick(conv)}
                >
                  <span className="conversation-preview">{conv.title}</span>
                  <div className="menu-container">
                    <button className="drop-btn" onClick={(e) => handleMenuClick(e, conv.id)}>
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {menuOpen === conv.id && (
                      <div className="dropdown-menu">
                        <button className="delete-btn" onClick={(e) => handleDeleteClick(e, conv.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {userInfo && (
          <div className="sidebar-footer">
            <ProfileSection
              name={userInfo?.name || ''}
              imageUrl={userInfo?.picture || ''}
              onOpenSettings={onOpenSettings}
              onOpenAnalytics={onOpenAnalytics}
              // onLogout={onLogout} 
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;