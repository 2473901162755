// src/components/utils.ts

import { Conversation, ReturnedMessage } from './types';
import { apiCall } from '../apiWrapper';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchConversations = async (
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  try {
    const data = await apiCall<Conversation[]>(`${API_URL}/conversations`);
    
    // Sort conversations by updated_at in descending order
    const sortedConversations = data.sort((a: Conversation, b: Conversation) => 
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );
    
    setConversations(sortedConversations);
  } catch (error) {
    console.error('Error fetching conversations:', error);
    setError("Failed to fetch conversations.");
  }
};

export const createNewConversation = async (
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>,
  setCurrentConversation: React.Dispatch<React.SetStateAction<Conversation | null>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  try {
    const newConversation = await apiCall<Conversation>(`${API_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: '' }),
    });
    setConversations(prev => [newConversation, ...prev]);
    setCurrentConversation(newConversation);
  } catch (error) {
    console.error('Error creating new conversation:', error);
    setError("Failed to create new conversation.");
  }
};

export const deleteConversation = async (
  conversationId: string,
  setConversations: React.Dispatch<React.SetStateAction<Conversation[]>>,
  setCurrentConversation: React.Dispatch<React.SetStateAction<Conversation | null>>,
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  try {
    await apiCall(`${API_URL}/conversations/${conversationId}`, {
      method: 'DELETE',
    });
    setConversations(prev => prev.filter(conv => conv.id !== conversationId));
    setCurrentConversation(prev => prev?.id === conversationId ? null : prev);
  } catch (error) {
    console.error('Error deleting conversation:', error);
    setError("Failed to delete conversation.");
  }
};

export const fetchConversationMessages = async (conversationId: string): Promise<ReturnedMessage[]> => {
  try {
    const data = await apiCall<{messages: ReturnedMessage[]}>(`${API_URL}/conversations/${conversationId}`);
    // Change sender_type to role
    data.messages.forEach((message: any) => {
      message.role = message.sender_type;
      delete message.sender_type;
    });
    console.log('Fetched conversation messages:', data.messages);
    return data.messages;
  } catch (error) {
    console.error('Error fetching conversation messages:', error);
    throw error;
  }
};

export const getFileIcon = (fileType: string) => {
  if (fileType.includes('image')) return 'fa-file-image';
  if (fileType.includes('pdf') || (fileType === 'application/pdf')) return 'fa-file-pdf';
  if (fileType.includes('word')) return 'fa-file-word';
  if (fileType.includes('excel') || fileType.includes('sheet') || (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) return 'fa-file-excel';
  if (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return 'fa-file-powerpoint';
  if (fileType.includes('json')) return 'fa-file-code';
  if (fileType === 'text/plain') return 'fa-file-alt';
  return 'fa-file';
};