// useScrollBehavior.ts
import { useCallback, useEffect, useRef, useState } from 'react';

export function useScrollBehavior(
  chatContainerRef: React.RefObject<HTMLDivElement>,
  lastMessageRef: React.RefObject<HTMLDivElement>
) {
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const lastScrollTopRef = useRef<number>(0);
  const userHasScrolledUpRef = useRef(false);
  const bottomPadding = 100;

  const isNearBottom = useCallback(() => {
    const container = chatContainerRef.current;
    if (!container) return false;
    
    const { scrollTop, scrollHeight, clientHeight } = container;
    return scrollHeight - scrollTop - clientHeight < bottomPadding + 50;
  }, [bottomPadding]);

  const scrollToBottom = useCallback((smooth = true) => {
    // Don't scroll if user has manually scrolled up and hasn't returned to bottom
    if (userHasScrolledUpRef.current && !isNearBottom()) {
      return;
    }

    if (chatContainerRef.current && lastMessageRef.current) {
      const container = chatContainerRef.current;
      const lastMessage = lastMessageRef.current;
      
      const scrollTarget = Math.max(
        0,
        lastMessage.offsetTop - container.clientHeight + lastMessage.clientHeight + bottomPadding
      );

      container.scrollTo({
        top: scrollTarget,
        behavior: smooth ? 'smooth' : 'auto'
      });
    }
  }, [bottomPadding, isNearBottom]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (!container) return;

    let isManualScroll = false;

    const handleScroll = () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      const { scrollTop } = container;
      const scrollDiff = Math.abs(scrollTop - lastScrollTopRef.current);
      
      // Only consider it manual scroll if user moves significantly
      if (scrollDiff > 50) {
        isManualScroll = true;
        setIsUserScrolling(true);
      }

      // If near bottom after manual scroll, reset everything
      if (isManualScroll && isNearBottom()) {
        isManualScroll = false;
        setIsUserScrolling(false);
        setShouldAutoScroll(true);
        userHasScrolledUpRef.current = false;
      } 
      // If scrolling up manually, disable auto-scroll and mark user scroll
      else if (isManualScroll && scrollTop < lastScrollTopRef.current) {
        setShouldAutoScroll(false);
        userHasScrolledUpRef.current = true;
      }

      lastScrollTopRef.current = scrollTop;
    };

    const handleWheel = (e: WheelEvent) => {
      // Immediately disable auto-scroll when scrolling up
      if (e.deltaY < 0) {
        setShouldAutoScroll(false);
        setIsUserScrolling(true);
        userHasScrolledUpRef.current = true;
      }
      // Re-enable auto-scroll only if manually scrolled to bottom
      else if (e.deltaY > 0 && isNearBottom()) {
        setIsUserScrolling(false);
        setShouldAutoScroll(true);
        userHasScrolledUpRef.current = false;
      }
    };

    const handleTouchStart = () => {
      setIsUserScrolling(true);
    };

    const handleTouchEnd = () => {
      if (isNearBottom()) {
        setIsUserScrolling(false);
        setShouldAutoScroll(true);
        userHasScrolledUpRef.current = false;
      }
      
      scrollTimeoutRef.current = setTimeout(() => {
        setIsUserScrolling(false);
      }, 150);
    };

    container.addEventListener('scroll', handleScroll, { passive: true });
    container.addEventListener('wheel', handleWheel, { passive: true });
    container.addEventListener('touchstart', handleTouchStart, { passive: true });
    container.addEventListener('touchend', handleTouchEnd, { passive: true });

    return () => {
      container.removeEventListener('scroll', handleScroll);
      container.removeEventListener('wheel', handleWheel);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [chatContainerRef, bottomPadding, isNearBottom]);

  return { 
    shouldAutoScroll, 
    setShouldAutoScroll,
    scrollToBottom, 
    isUserScrolling,
    isNearBottom
  };
}