// src/components/PerformanceDebug.ts
export const DEBUG = process.env.NODE_ENV === 'development';

export const PerformanceDebug = {
  timers: new Map<string, number>(),
  
  start(label: string) {
    if (!DEBUG) return;
    this.timers.set(label, performance.now());
    console.log(`🏁 Starting: ${label}`);
  },
  
  end(label: string) {
    if (!DEBUG) return;
    const startTime = this.timers.get(label);
    if (startTime) {
      const duration = performance.now() - startTime;
      console.log(`✅ ${label}: ${duration.toFixed(2)}ms`);
      this.timers.delete(label);
    }
  },

  log(label: string, data?: any) {
    if (!DEBUG) return;
    console.log(`📝 ${label}`, data);
  }
};