// src/components/MessageList/components/MessageFiles.tsx
import React from 'react';
import { FileMetadata } from '../../types';
import ImageParticleAnimation from '../ImageParticleAnimation';

interface MessageFilesProps {
  files: FileMetadata[] | undefined;
  msgIndex: number;
}

export function MessageFiles({ files, msgIndex }: MessageFilesProps) {
  if (!files || files.length === 0) return null;

  return (
    <div className="file-container">
      {files.map((file, fileIndex) => (
        file.type.startsWith('image/') ? (
          <ImageParticleAnimation
            key={`msg-${msgIndex}-file-${fileIndex}-${file.url}`}
            src={file.url}
            alt={file.name}
            justUploaded={file.justUploaded || false}
          />
        ) : (
          <a
            key={`msg-${msgIndex}-file-${fileIndex}-${file.url}`}
            href={file.url}
            target="_blank"
            rel="noopener noreferrer"
            className="file-link"
          >
            {file.name}
          </a>
        )
      ))}
    </div>
  );
}