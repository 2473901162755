import React, { useState } from 'react';
import { User, Settings, BarChart2, LogOut, X, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface DropdownItemProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  onClick?: () => void;
  isHighlighted?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

interface DropdownMenuProps {
  onOpenSettings: () => void;
  onOpenAnalytics: () => void;
  onClose: () => void;
  onLogout: () => Promise<void>; // Ajout de la prop onLogout
}

const DropdownItem: React.FC<DropdownItemProps> = ({ 
  children, 
  icon, 
  onClick, 
  isHighlighted,
  isLoading,
  disabled
}) => {
  return (
    <div className={`menu-item-wrapper ${isHighlighted ? 'highlighted' : ''}`}>
      <button 
        className="menu-item" 
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        <span className="menu-item-icon">
          {isLoading ? <Loader className="animate-spin" size={18} /> : icon}
        </span>
        <span className="menu-item-text">{children}</span>
      </button>
    </div>
  );
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({ 
  onOpenSettings, 
  onOpenAnalytics, 
  onClose,
  onLogout
}) => {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await onLogout();
      
      // Fermeture du dropdown avant la redirection
      onClose();
      
      // Petit délai pour laisser l'animation se terminer
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 300);
    } catch (error) {
      console.error('Logout error:', error);
      // Ici tu pourrais ajouter une notification d'erreur pour l'utilisateur
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <div className="dropdown">
      <div className="dropdown-header">
        <h2 className="dropdown-title">User Menu</h2>
        <button 
          className="close-icon" 
          onClick={onClose}
          aria-label="Close menu"
        >
          <X size={18} />
        </button>
      </div>
      <div className="dropdown-content">
        <DropdownItem 
          icon={<User size={18} />}
          onClick={() => navigate('/profile')}
        >
          My Profile
        </DropdownItem>
        <DropdownItem 
          icon={<Settings size={18} />} 
          onClick={onOpenSettings}
        >
          Settings
        </DropdownItem>
        <DropdownItem 
          icon={<BarChart2 size={18} />} 
          onClick={onOpenAnalytics}
        >
          Analytics
        </DropdownItem>
        <DropdownItem 
          icon={<LogOut size={18} />} 
          onClick={handleLogout}
          isLoading={isLoggingOut}
          disabled={isLoggingOut}
        >
          {isLoggingOut ? 'Logging out...' : 'Logout'}
        </DropdownItem>
      </div>
    </div>
  );
};

export default DropdownMenu;