// src/components/MessageList/DocumentPreview.tsx

import React from 'react';
import { DocumentRef } from '../../types';

interface DocumentPreviewProps {
  document: DocumentRef;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document }) => {
  // Function to preserve line breaks and limit to first 5 lines
  const formatPreviewText = (text: string | undefined | null) => {
    if (!text) return ''; // Return empty string if text is undefined or null
    
    const lines = text.split('\n').slice(0, 5);
    return lines.join('\n') + (lines.length === 5 ? '...' : '');
  };

  // Add error boundary UI for when document is invalid
  if (!document) {
    return (
      <div className="document-preview document-preview-error">
        <p>Error: Invalid document reference</p>
      </div>
    );
  }

  return (
    <div className="document-preview">
      <div className="document-preview-content">
        <pre>{formatPreviewText(document?.content)}</pre>
      </div>
      <div className="gradient-overlay"></div>
    </div>
  );
};

export default DocumentPreview;