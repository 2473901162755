//src/components/MessageList/components/ConfirmationUI.tsx
import React from 'react';
import { ImageIcon, Search } from 'lucide-react';

const ConfirmationUI = ({ confirmation, onConfirm }: any) => {
  if (!confirmation) return null;

  return (
    <div className="confirmation-container">
      <div className="confirmation-box">
        <div className="confirmation-header">
          <div className="confirmation-icon-container">
            {confirmation.tool === 'generate_image' ? 
              <ImageIcon className="confirmation-icon" /> : 
              <Search className="confirmation-icon" />
            }
          </div>
          <div className="confirmation-text-container">
            <p className="confirmation-message">
              {confirmation.message}
            </p>
            <div className="confirmation-buttons">
              <button
                onClick={() => onConfirm(confirmation.tool)}
                className="confirmation-button-yes"
              >
                Yes
              </button>
              <button
                onClick={() => onConfirm('cancel')}
                className="confirmation-button-no"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationUI;