// App.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Chat from './components/Chat/Chat';
import Login from './components/Login/Login';
import { UserInfo, TokenResponse, ThemeType } from './components/types';
import { apiCall, refreshToken } from './apiWrapper';
import "./App.css";
import SettingsOverlay from './components/Sidebar/Profile/SettingsOverlay/SettingsOverlay';
import AnalyticsOverlay from './components/Sidebar/Profile/AnalyticsOverlay/AnalyticsOverlay';

const API_URL = process.env.REACT_APP_API_URL;
const GOOGLE_CLIENT_ID = '667159842858-otcuurrnct5clgcg7uor57u2i395uf0o.apps.googleusercontent.com';

// Add this outside the component to cache the verification promise
let verifyPromise: Promise<any> | null = null;

function App() {
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const [theme, setTheme] = useState<ThemeType>('dark');

  const handleInvalidToken = useCallback(() => {
    setIsValidToken(false);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiry');
    verifyPromise = null; // Reset the promise when token is invalid
  }, []);

  const fetchUserInfo = useCallback(async () => {
    try {
      const data = await apiCall<UserInfo>(`${API_URL}/user_info`);
      setUserInfo(data);
    } catch (error) {
      console.error('Error fetching user info:', error);
      handleInvalidToken();
    }
  }, [handleInvalidToken]);

  const verifyToken = useCallback(async () => {
    // Return existing promise if verification is in progress
    if (verifyPromise) {
      return verifyPromise;
    }

    verifyPromise = (async () => {
      try {
        const data = await apiCall<TokenResponse>(`${API_URL}/verify_token`);
        if (data.valid) {
          setIsValidToken(true);
          await fetchUserInfo();
        } else if (data.error === 'TOKEN_EXPIRED') {
          const refreshTokenValue = localStorage.getItem('refreshToken');
          if (refreshTokenValue) {
            const newTokens = await refreshToken();
            if (newTokens && newTokens.access_token) {
              setIsValidToken(true);
              await fetchUserInfo();
            } else {
              handleInvalidToken();
            }
          } else {
            handleInvalidToken();
          }
        } else {
          handleInvalidToken();
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        handleInvalidToken();
      } finally {
        setIsLoading(false);
        // Reset promise after a delay
        setTimeout(() => {
          verifyPromise = null;
        }, 1000);
      }
    })();

    return verifyPromise;
  }, [fetchUserInfo, handleInvalidToken]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyToken();
    } else {
      setIsLoading(false);
    }
  }, [verifyToken]);

  if (isLoading) {
    return <div className='loading'>Racine</div>;
  }

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };
    
  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };
  
  const handleOpenAnalytics = () => {
      setIsAnalyticsOpen(true);
      console.log(isAnalyticsOpen)
  };
  
  const handleCloseAnalytics = () => {
      setIsAnalyticsOpen(false);
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Router>
        <div className={`app-container ${theme}`}>
          <Routes>
            <Route path="/" element={
              isValidToken ?
                <>
                  <Chat 
                    userInfo={userInfo} 
                    onOpenSettings={handleOpenSettings}
                    onOpenAnalytics={handleOpenAnalytics}
                    theme={theme} 
                    setTheme={setTheme} 
                  />
                  {isSettingsOpen && (
                    <SettingsOverlay
                      isOpen={isSettingsOpen}
                      onClose={handleCloseSettings}
                      currentTheme={theme}
                      onThemeChange={setTheme}
                    />
                  )}
                  <AnalyticsOverlay
                    isOpen={isAnalyticsOpen}
                    onClose={handleCloseAnalytics}
                  />
                </> :
                <Navigate to="/login" replace />
            } />
            <Route path="/login" element={
              isValidToken ?
                <Navigate to="/" replace /> :
                <Login setIsValidToken={setIsValidToken} fetchUserInfo={fetchUserInfo} />
            } />
          </Routes>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;