// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Typewriter.css */

.login-title {
  font-family: 'Rethink Sans', sans-serif;
  font-weight: 800;
  font-size: 80px;
  color: var(--highlight);
  margin-bottom: 24px;
  text-align: center;
  white-space: normal; /* Changed from nowrap to normal */
  overflow-wrap: break-word; /* Added to allow breaking of long words */
  word-wrap: break-word; /* For better browser compatibility */
  max-width: 90vw; /* Limit the maximum width to 90% of the viewport width */
}

.cursor {
  display: inline-block;
  background-color: var(--highlight);
  width: 10px;
  max-width: 90vw;
  height: 1em;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0%, 50% {
    opacity: 1;
  }
  50.01%, 100% {
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Typewriter.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,uCAAuC;EACvC,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB,EAAE,kCAAkC;EACvD,yBAAyB,EAAE,0CAA0C;EACrE,qBAAqB,EAAE,qCAAqC;EAC5D,eAAe,EAAE,yDAAyD;AAC5E;;AAEA;EACE,qBAAqB;EACrB,kCAAkC;EAClC,WAAW;EACX,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* src/components/Typewriter.css */\n\n.login-title {\n  font-family: 'Rethink Sans', sans-serif;\n  font-weight: 800;\n  font-size: 80px;\n  color: var(--highlight);\n  margin-bottom: 24px;\n  text-align: center;\n  white-space: normal; /* Changed from nowrap to normal */\n  overflow-wrap: break-word; /* Added to allow breaking of long words */\n  word-wrap: break-word; /* For better browser compatibility */\n  max-width: 90vw; /* Limit the maximum width to 90% of the viewport width */\n}\n\n.cursor {\n  display: inline-block;\n  background-color: var(--highlight);\n  width: 10px;\n  max-width: 90vw;\n  height: 1em;\n  margin-left: 2px;\n  animation: blink 0.7s infinite;\n}\n\n@keyframes blink {\n  0%, 50% {\n    opacity: 1;\n  }\n  50.01%, 100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
