// MessageList/index.tsx

import React from 'react';
import { Message } from '../types';
import { ScrollManager } from './hooks/useScrollBehavior';
import { useCodeBlockStates } from './hooks/useCodeBlockStates';
import { MessageContent } from './components/MessageContent';
import { withRenderLogging } from '../../DebugRenders';
import { DEBUG, PerformanceDebug } from '../PerformanceDebug';
import './MessageList.css';
import './Markdown.css';

const CHUNK_SIZE = 20;

interface MessageListProps {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  fetchUpdatedConversation: (conversationId: string) => Promise<void>;
  currentConversationId: string | null;
  theme: 'dark' | 'light';
  onConfirm: (tool: string) => void;
}

interface MessageListState {
  visibleRange: {
    start: number;
    end: number;
  };
}

class MessageListComponent extends React.Component<MessageListProps, MessageListState> {
  private scrollManager: ScrollManager;
  private lastMessageRef: React.RefObject<HTMLDivElement>;
  private loadMoreTriggerRef: React.RefObject<HTMLDivElement>;
  private observer: ResizeObserver | null = null;
  private intersectionObserver: IntersectionObserver | null = null;
  private prevMessagesLength: number = 0;
  private prevConversationId: string | null = null;
  private lastMessageLength: number = 0;
  private lastLoadingState: boolean = false;

  private metrics = {
    renderCount: 0,
    messageProcessingTime: 0,
    scrollHandlingTime: 0,
    lastRenderTime: Date.now()
  };

  constructor(props: MessageListProps) {
    super(props);
    this.scrollManager = new ScrollManager();
    this.lastMessageRef = React.createRef();
    this.loadMoreTriggerRef = React.createRef();

    this.state = {
      visibleRange: {
        start: Math.max(0, props.messages.length - CHUNK_SIZE),
        end: props.messages.length
      }
    };
  }

  // Capture scroll information before the DOM updates
  getSnapshotBeforeUpdate(prevProps: MessageListProps, prevState: MessageListState) {
    if (this.props.messages.length > prevProps.messages.length) {
      const container = this.props.chatContainerRef.current;
      if (container) {
        return {
          scrollHeightBefore: container.scrollHeight,
          scrollTopBefore: container.scrollTop
        };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps: MessageListProps, prevState: MessageListState, snapshot: any) {
    // Handle conversation switches
    if (this.props.currentConversationId !== this.prevConversationId) {
      this.handleConversationSwitch();
    }

    // Handle message updates
    this.handleMessageUpdates(prevProps);

    // Adjust scroll position if new messages were added
    if (snapshot) {
      const container = this.props.chatContainerRef.current;
      if (container) {
        const { scrollHeightBefore, scrollTopBefore } = snapshot;
        const heightDifference = container.scrollHeight - scrollHeightBefore;
        container.scrollTop = scrollTopBefore + heightDifference;
      }
    }

    // Update previous messages length
    this.prevMessagesLength = this.props.messages.length;
  }

  componentDidMount() {
    if (this.props.chatContainerRef.current) {
      this.scrollManager.setContainer(this.props.chatContainerRef.current);
      
      this.observer = new ResizeObserver(() => {
        if (this.scrollManager.getShouldAutoScroll()) {
          this.scrollManager.scrollToBottom();
        }
      });
      
      this.observer.observe(this.props.chatContainerRef.current);
      this.setupIntersectionObserver();
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  private handleConversationSwitch() {
    PerformanceDebug.start('conversationSwitch');
    
    this.prevConversationId = this.props.currentConversationId;
    this.setState({
      visibleRange: {
        start: Math.max(0, this.props.messages.length - CHUNK_SIZE),
        end: this.props.messages.length
      }
    });
    
    if (DEBUG) {
      PerformanceDebug.log('Conversation switch metrics', {
        previousId: this.prevConversationId,
        newId: this.props.currentConversationId,
        messageCount: this.props.messages.length,
        processingTime: this.metrics.messageProcessingTime
      });
    }
    
    PerformanceDebug.end('conversationSwitch');
  }

  private handleMessageUpdates(prevProps: MessageListProps) {
    const lastMessage = this.props.messages[this.props.messages.length - 1];
    const currentLastMessageLength = lastMessage?.text?.length || 0;
    const messageChanged = currentLastMessageLength !== this.lastMessageLength;
    
    if (this.lastLoadingState && !this.props.isLoading) {
      this.lastLoadingState = this.props.isLoading;
      this.lastMessageLength = currentLastMessageLength;
      return;
    }
    
    this.lastLoadingState = this.props.isLoading;

    if (this.scrollManager.getShouldAutoScroll() && (
      (this.props.isLoading && messageChanged) ||
      (this.props.messages.length > this.prevMessagesLength && this.scrollManager.isNearBottom())
    )) {
      this.scrollManager.scrollToBottom();
      this.setState({
        visibleRange: {
          start: Math.max(0, this.props.messages.length - CHUNK_SIZE),
          end: this.props.messages.length
        }
      });
    }

    this.lastMessageLength = currentLastMessageLength;
  }

  private setupIntersectionObserver() {
    if (!this.props.chatContainerRef.current || !this.loadMoreTriggerRef.current) return;

    const options = {
      root: this.props.chatContainerRef.current,
      threshold: [0, 0.1, 1.0],
      rootMargin: '100px 0px'
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && this.state.visibleRange.start > 0) {
          this.setState(prevState => ({
            visibleRange: {
              start: Math.max(0, prevState.visibleRange.start - CHUNK_SIZE),
              end: this.props.messages.length
            }
          }));
        }
      });
    }, options);

    this.intersectionObserver.observe(this.loadMoreTriggerRef.current);
  }

  private updateVisibleRange = () => {
    this.setState(prevState => ({
      visibleRange: {
        start: Math.max(0, prevState.visibleRange.start - CHUNK_SIZE),
        end: this.props.messages.length
      }
    }));
  };

  private processMessages() {
    PerformanceDebug.start('processMessages');
    const startTime = performance.now();
    
    const processedMessages = this.props.messages.reduce((acc: Message[], curr, index) => {
      if (index === 0) return [{ ...curr }];

      const lastMessage = acc[acc.length - 1];
      
      if (lastMessage.role !== curr.role) {
        acc.push({ ...curr });
        return acc;
      }

      if (curr.role === 'user') return acc;

      if (curr.role === 'assistant') {
        acc[acc.length - 1] = {
          ...lastMessage,
          text: (curr.text?.length ?? 0) > (lastMessage.text?.length ?? 0)
            ? curr.text 
            : lastMessage.text,
          files: [...(lastMessage.files ?? []), ...(curr.files ?? [])],
          searchResults: curr.searchResults 
            ? [...(lastMessage.searchResults ?? []), ...curr.searchResults]
            : lastMessage.searchResults,
          confirmation: curr.confirmation ?? lastMessage.confirmation
        };
      }

      return acc;
    }, []);

    const endTime = performance.now();
    this.metrics.messageProcessingTime = endTime - startTime;
    
    PerformanceDebug.end('processMessages');
    
    return processedMessages.slice(this.state.visibleRange.start, this.state.visibleRange.end);
  }

  render() {
    const { isLoading, error, theme, onConfirm } = this.props;
    const memoizedMessages = this.processMessages();
    
    if (DEBUG) {
      this.metrics.renderCount++;
      const now = Date.now();
      PerformanceDebug.log('Render metrics', {
        renderCount: this.metrics.renderCount,
        timeSinceLastRender: now - this.metrics.lastRenderTime,
        visibleMessages: memoizedMessages.length,
        totalMessages: this.props.messages.length,
        visibleRange: this.state.visibleRange
      });
      this.metrics.lastRenderTime = now;
    }

    return (
      <div className="messages" ref={this.props.chatContainerRef}>
        {this.state.visibleRange.start > 0 && (
          <div 
            ref={this.loadMoreTriggerRef} 
            className="load-more-trigger top"
            aria-hidden="true"
          />
        )}
        
        {memoizedMessages.map((message, index) => (
          <div 
            key={`msg-${index}-${message.text?.substring(0, 20) || 'no-text'}`}
            className={`chat-message ${message.role} ${
              (message.document_refs?.length ?? 0) > 0 && (!message.text || message.text.trim() === "") 
                ? 'document-only' 
                : ''
            }`}
            ref={index === memoizedMessages.length - 1 ? this.lastMessageRef : null}
          >
            <MessageContent 
              message={message}
              index={index + this.state.visibleRange.start}
              codeBlockStates={{}} // You'll need to implement this
              theme={theme}
              onConfirm={onConfirm}
            />
          </div>
        ))}
        
        {isLoading && (
          <div className="spinner-container">
            <div className="spinner" />
          </div>
        )}
        
        {error && (
          <div className="chat-message assistant error">
            {error}
          </div>
        )}
      </div>
    );
  }
}

const MemoizedMessageList = React.memo(MessageListComponent);
const LoggedMessageList = withRenderLogging(MemoizedMessageList, 'MessageList');

export default LoggedMessageList;
