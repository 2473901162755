// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("RethinkSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("RethinkSans-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Consolas.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */

@font-face {
  font-family: 'Rethink';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rethink-bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Consolas';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

.loading {
  background-color: #E4DBCC;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  font-size: 60px;
}

.app {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.sidebar.open {
  transform: translateX(0);
}

.App-header {
  flex-grow: 1;
  transition: margin-left 0.3s ease, width 0.3s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.app.sidebar-open .App-header {
  margin-left: 13vw;
  width: calc(100% - 13vw);
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;EACE,sBAAsB;EACtB,+DAAsD;EACtD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,+DAAwD;EACxD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,+DAA2C;EAC3C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;;EAEZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,kDAAkD;EAClD,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,wBAAwB;AAC1B","sourcesContent":["/* src/App.css */\n\n@font-face {\n  font-family: 'Rethink';\n  src: url('RethinkSans-Regular.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Rethink-bold';\n  src: url('RethinkSans-ExtraBold.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Consolas';\n  src: url('Consolas.TTF') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n.loading {\n  background-color: #E4DBCC;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n\n  font-size: 60px;\n}\n\n.app {\n  display: flex;\n  height: 100vh;\n  width: 100vw;\n}\n\n.sidebar.open {\n  transform: translateX(0);\n}\n\n.App-header {\n  flex-grow: 1;\n  transition: margin-left 0.3s ease, width 0.3s ease;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh;\n}\n\n.app.sidebar-open .App-header {\n  margin-left: 13vw;\n  width: calc(100% - 13vw);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
