import React, { useRef, useState, useEffect } from 'react';
import MessageList from '../MessageList';
import InputArea from '../InputArea/InputArea';
import { Conversation, Message, DocumentRef, FileMetadata, ThemeType } from '../types';
interface StopGenerationButtonProps {
  isGenerating: boolean;
  onStop: () => void;
}

const StopGenerationButton: React.FC<StopGenerationButtonProps> = ({ 
  isGenerating, 
  onStop 
}) => {
  if (!isGenerating) return null;
  
  return (
    <button 
      className="absolute right-2 top-2 p-2 rounded bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600"
      onClick={onStop}
    >
      Stop generating
    </button>
  );
};

interface ChatContentProps {
  currentConversation: Conversation | null;
  isLoading: boolean;
  error: string | null;
  onFilesDropped?: (files: File[]) => void;
  theme: ThemeType;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  handleSend: (completeInput: string, input: string, tempFiles: FileMetadata[], documents: DocumentRef[]) => Promise<void>;
  setTheme: (theme: ThemeType) => void;
  isSidebarOpen: boolean;
  fetchUpdatedConversation: (conversationId: string) => Promise<void>;
  handleConfirmation: (tool: string) => void;
  stopGeneration: () => void;
  isGenerating: boolean; // Ajout de isGenerating dans les props
}

const ChatContent = React.forwardRef<
  { handleFilesDropped: (files: File[]) => void },
  ChatContentProps
>(({
  currentConversation,
  isLoading,
  error,
  theme,
  chatContainerRef,
  handleSend,
  setTheme,
  isSidebarOpen,
  fetchUpdatedConversation,
  handleConfirmation,
  stopGeneration,
  isGenerating,
}, ref) => {
  const inputAreaRef = useRef<{ handleFileUpload: (file: File) => void }>(null);
  const [isAssistantResponding, setIsAssistantResponding] = useState<boolean>(false);
  const [pastedDocumentRefs, setPastedDocumentRefs] = useState<DocumentRef[]>([]);
  const messageListRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleSendWithDocuments = async (input: string, tempFiles: FileMetadata[]) => {
    setIsAssistantResponding(true);
    
    try {
      const completeInput = [       
        ...pastedDocumentRefs.map(doc => doc.type === 'image' ? `[Image: ${doc.id}]` : doc.content),
        input
      ].join('\n\n');  
      
      await handleSend(completeInput, input, tempFiles, pastedDocumentRefs);
    } finally {
      setIsAssistantResponding(false);
      setPastedDocumentRefs([]); // Clear pasted documents after sending
    }
  };
  React.useImperativeHandle(ref, () => ({
    handleFilesDropped: (files: File[]) => {
      files.forEach(file => {
        if (inputAreaRef.current) {
          inputAreaRef.current.handleFileUpload(file);
        }
      });
    }
  }));
  const mapMessages = (messages: any[]): Message[] => {
    console.log('Mapping messages:', messages);
    return messages.map(msg => {
      // Create the base message object
      const mappedMessage: Message = {
        role: msg.role === 'user' ? 'user' : 'assistant',
        text: msg.content || msg.text || '',
        files: msg.files || [],
        searchResults: msg.searchResults || [],
        document_refs: msg.document_refs || [],
        conversationId: currentConversation?.id,
      };
  
      // Explicitly check and add the confirmation object if it exists
      if (msg.confirmation) {
        mappedMessage.confirmation = {
          tool: msg.confirmation.tool,
          message: msg.confirmation.message
        };
      }
  
      console.log('Mapped message:', mappedMessage);
      return mappedMessage;
    });
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.contains(e.relatedTarget as Node)) {
      return;
    }
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  
    const files = Array.from(e.dataTransfer.files);
    files.forEach(file => {
      if (inputAreaRef.current) {
        inputAreaRef.current.handleFileUpload(file);
      }
    });
  };
  
  
  const handlePastedDocument = (document: DocumentRef) => {
    console.log('Pasted document:', document);
    setPastedDocumentRefs(prev => [...prev, document]);
  };

  const removePastedDocument = (id: string) => {
    setPastedDocumentRefs(prev => prev.filter(doc => doc.id !== id));
  };


  const updatePastedDocument = (updatedDocument: DocumentRef) => {
    setPastedDocumentRefs(prev =>
      prev.map(doc => doc.id === updatedDocument.id ? updatedDocument : doc)
    );
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const atBottom = scrollHeight - scrollTop <= clientHeight + 50;
      setShowScrollButton(!atBottom);
    }
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleFilesDropped = (files: File[]) => {
    // Transmettre les fichiers à InputArea
    if (inputAreaRef.current) {
      files.forEach(file => {
        inputAreaRef.current?.handleFileUpload(file);
      });
    }
  };
  
  return (
    <div 
      className="chat-content"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="messages-container" ref={messageListRef}>
        <MessageList
          messages={currentConversation ? mapMessages(currentConversation.messages) : []}
          isLoading={isLoading}
          error={error}
          chatContainerRef={messageListRef}
          fetchUpdatedConversation={fetchUpdatedConversation}
          currentConversationId={currentConversation?.id || null}
          theme={theme === "dark" ? "dark" : "light"}
          onConfirm={handleConfirmation}
        />
      </div>
      <div className="input-area-wrapper">
        {isDragging && (
          <div className="drag-drop-overlay active">
            <div className="drag-drop-content">
              <svg 
                viewBox="0 0 24.00 24.00" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
                className="drag-drop-icon"
              >
                <path 
                  d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round" 
                  strokeLinejoin="round"
                />
                <path 
                  d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" 
                  stroke="currentColor" 
                  strokeWidth="1.5" 
                  strokeLinecap="round"
                />
              </svg>
              <div className="drag-drop-text">
                Drop your files here
              </div>
            </div>
          </div>
        )}
        <InputArea
          ref={inputAreaRef}
          onSend={handleSendWithDocuments}
          isAssistantResponding={isLoading}
          pastedDocumentRefs={pastedDocumentRefs}
          handlePastedDocument={handlePastedDocument}
          removePastedDocument={removePastedDocument}
          updatePastedDocument={updatePastedDocument}
          isSidebarOpen={isSidebarOpen}
          isGenerating={isGenerating}
          stopGeneration={stopGeneration}
        />
      </div>
      {showScrollButton && (
        <button className="scroll-to-bottom-button" onClick={scrollToBottom}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      )}
    </div>
  );
});
export default ChatContent;